/* eslint-disable vue/require-valid-default-prop */
<template>
  <div class="memo-container">
    <h5 class="desp" style="font-size: 12px">
      備　忘　録　（やった方が良いことで、まだ着手していないことやできていないことなど。重要度とは売上への直結する度合いで考える）
    </h5>
    <div class="section">
      <div class="block">
        <div class="item not-important">
          <div class="index" style="font-size: 10px">No.</div>
          <div class="title">「緊急」＆「重要度が高い」</div>
          <div class="deadline">期限</div>
        </div>
        <draggable
          :list="momoState.notUrgentAndNotImportant"
          item-key="id"
          class="list-group"
          ghost-class="ghost"
          @change="onMove"
          @start="dragging = true"
          @end="dragging = false"
        >
          <template #item="{ element, index }">
            <div class="item">
              <div class="index">{{ index + 1 }}</div>
              <div
                class="title"
                @mouseenter="focusMemo = 'notUrgentAndNotImportant' + element.id"
                @mouseleave="focusMemo = ''"
              >
                <el-checkbox
                  v-if="element.content?.trim()"
                  fill="#2c3e50"
                  v-model="element.isChecked"
                  :disabled="!editoralbe"
                  @change="
                    (value) => {
                      onFinish(value, element)
                    }
                  "
                />
                <el-popover
                  placement="bottom-start"
                  width="auto"
                  trigger="hover"
                  :disabled="!element.content?.trim() || dragging"
                  :offset="-1"
                >
                  <div v-html="element.content"></div>
                  <template #reference>
                    <div
                      @click.stop="openEditor(element)"
                      style="display: flex; align-items: center; height: 100%; overflow: hidden"
                    >
                      <div
                        :style="{
                          flex: 1,
                          textDecoration: element.isChecked ? 'line-through' : 'none'
                        }"
                        class="paragraph"
                        v-for="(item2, index) in parseTags(element.content)"
                        :key="index"
                        v-html="item2"
                      ></div>
                    </div>
                  </template>
                </el-popover>
                <div
                  class="iconWrapper"
                  @click.stop="onDelete(element)"
                  v-if="
                    element.content?.trim() &&
                    editoralbe &&
                    focusMemo === 'notUrgentAndNotImportant' + element.id
                  "
                >
                  <i class="el-icon-circle-close"></i>
                </div>
              </div>
              <div class="deadline">
                <el-date-picker
                  v-model="element.date"
                  format="MM/DD"
                  value-format="YYYY-MM-DD"
                  placeholder="日付"
                  prefix-icon="aaa"
                  type="date"
                  :clearable="false"
                  :disabled="!editoralbe"
                  @change="handleChange(element, $event)"
                >
                </el-date-picker>
              </div>
            </div>
          </template>
        </draggable>
      </div>
      <div class="block">
        <div class="item not-very-important">
          <div class="index" style="font-size: 10px">No.</div>
          <div class="title">「不急」＆「重要度が高い」</div>
          <div class="deadline">期限</div>
        </div>
        <draggable
          :list="momoState.notUrgentAndVeryImportant"
          item-key="id"
          class="list-group"
          ghost-class="ghost"
          @change="onMove"
          @start="dragging = true"
          @end="dragging = false"
        >
          <template #item="{ element, index }">
            <div class="item">
              <div class="index">{{ index + 1 }}</div>
              <div
                class="title"
                @mouseenter="focusMemo = 'notUrgentAndVeryImportant' + element.id"
                @mouseleave="focusMemo = ''"
              >
                <el-checkbox
                  v-if="element.content?.trim()"
                  fill="#2c3e50"
                  v-model="element.isChecked"
                  :disabled="!editoralbe"
                  @change="
                    (value) => {
                      onFinish(value, element)
                    }
                  "
                />
                <el-popover
                  placement="bottom-start"
                  width="auto"
                  trigger="hover"
                  :disabled="!element.content?.trim() || dragging"
                  :offset="-1"
                >
                  <div v-html="element.content"></div>
                  <template #reference>
                    <div
                      @click.stop="openEditor(element)"
                      style="display: flex; align-items: center; height: 100%; overflow: hidden"
                    >
                      <div
                        :style="{
                          flex: 1,
                          textDecoration: element.isChecked ? 'line-through' : 'none'
                        }"
                        class="paragraph"
                        v-for="(item2, index) in parseTags(element.content)"
                        :key="index"
                        v-html="item2"
                      ></div>
                    </div>
                  </template>
                </el-popover>
                <div
                  class="iconWrapper"
                  @click.stop="onDelete(element)"
                  v-if="
                    element.content?.trim() &&
                    editoralbe &&
                    focusMemo === 'notUrgentAndVeryImportant' + element.id
                  "
                >
                  <i class="el-icon-circle-close"></i>
                </div>
              </div>
              <div class="deadline">
                <el-date-picker
                  v-model="element.date"
                  format="MM/DD"
                  value-format="YYYY-MM-DD"
                  placeholder="日付"
                  prefix-icon="aaa"
                  type="date"
                  :clearable="false"
                  :disabled="!editoralbe"
                  @change="handleChange(element, $event)"
                >
                </el-date-picker>
              </div>
            </div>
          </template>
        </draggable>
      </div>
      <div class="block">
        <div class="item urgent-not-important">
          <div class="index" style="font-size: 10px">No.</div>
          <div class="title">「緊急」＆「重要度が低い」</div>
          <div class="deadline">期限</div>
        </div>
        <draggable
          :list="momoState.urgentAndNotImportant"
          item-key="id"
          class="list-group"
          ghost-class="ghost"
          @change="onMove"
          @start="dragging = true"
          @end="dragging = false"
        >
          <template #item="{ element, index }">
            <div class="item">
              <div class="index">{{ index + 1 }}</div>
              <div
                class="title"
                @mouseenter="focusMemo = 'urgentAndNotImportant' + element.id"
                @mouseleave="focusMemo = ''"
              >
                <el-checkbox
                  v-if="element.content?.trim()"
                  fill="#2c3e50"
                  v-model="element.isChecked"
                  :disabled="!editoralbe"
                  @change="
                    (value) => {
                      onFinish(value, element)
                    }
                  "
                />
                <el-popover
                  placement="bottom-start"
                  width="auto"
                  trigger="hover"
                  :disabled="!element.content?.trim() || dragging"
                  :offset="-1"
                >
                  <div v-html="element.content"></div>
                  <template #reference>
                    <div
                      @click.stop="openEditor(element)"
                      style="display: flex; align-items: center; height: 100%; overflow: hidden"
                    >
                      <div
                        :style="{
                          flex: 1,
                          textDecoration: element.isChecked ? 'line-through' : 'none'
                        }"
                        class="paragraph"
                        v-for="(item2, index) in parseTags(element.content)"
                        :key="index"
                        v-html="item2"
                      ></div>
                    </div>
                  </template>
                </el-popover>
                <div
                  class="iconWrapper"
                  @click.stop="onDelete(element)"
                  v-if="
                    element.content?.trim() &&
                    editoralbe &&
                    focusMemo === 'urgentAndNotImportant' + element.id
                  "
                >
                  <i class="el-icon-circle-close"></i>
                </div>
              </div>
              <div class="deadline">
                <el-date-picker
                  v-model="element.date"
                  format="MM/DD"
                  value-format="YYYY-MM-DD"
                  placeholder="日付"
                  prefix-icon="aaa"
                  type="date"
                  :clearable="false"
                  :disabled="!editoralbe"
                  @change="handleChange(element, $event)"
                >
                </el-date-picker>
              </div>
            </div>
          </template>
        </draggable>
      </div>
      <div class="block">
        <div class="item urgent-not-very-important">
          <div class="index" style="font-size: 10px">No.</div>
          <div class="title">「不急」＆「重要度が低い」」</div>
          <div class="deadline el-time" style="border-right: none">期限</div>
        </div>
        <draggable
          :list="momoState.urgentAndVeryImportant"
          item-key="id"
          class="list-group"
          ghost-class="ghost"
          @change="onMove"
          @start="dragging = true"
          @end="dragging = false"
        >
          <template #item="{ element, index }">
            <div class="item">
              <div class="index">{{ index + 1 }}</div>
              <div
                class="title"
                @mouseenter="focusMemo = 'urgentAndVeryImportant' + element.id"
                @mouseleave="focusMemo = ''"
              >
                <el-checkbox
                  v-if="element.content?.trim()"
                  fill="#2c3e50"
                  v-model="element.isChecked"
                  :disabled="!editoralbe"
                  @change="
                    (value) => {
                      onFinish(value, element)
                    }
                  "
                />
                <el-popover
                  placement="bottom-start"
                  width="auto"
                  trigger="hover"
                  :disabled="!element.content?.trim() || dragging"
                  :offset="-1"
                >
                  <div v-html="element.content"></div>
                  <template #reference>
                    <div
                      @click.stop="openEditor(element)"
                      style="display: flex; align-items: center; height: 100%; overflow: hidden"
                    >
                      <div
                        :style="{
                          flex: 1,
                          textDecoration: element.isChecked ? 'line-through' : 'none'
                        }"
                        class="paragraph"
                        v-for="(item2, index) in parseTags(element.content)"
                        :key="index"
                        v-html="item2"
                      ></div>
                    </div>
                  </template>
                </el-popover>
                <div
                  class="iconWrapper"
                  @click.stop="onDelete(element)"
                  v-if="
                    element.content?.trim() &&
                    editoralbe &&
                    focusMemo === 'urgentAndVeryImportant' + element.id
                  "
                >
                  <i class="el-icon-circle-close"></i>
                </div>
              </div>
              <div class="deadline" style="border-right: none">
                <el-date-picker
                  v-model="element.date"
                  value-format="YYYY-MM-DD"
                  format="MM/DD"
                  placeholder="日付"
                  prefix-icon="aaa"
                  type="date"
                  :clearable="false"
                  :disabled="!editoralbe"
                  @change="handleChange(element, $event)"
                >
                </el-date-picker>
              </div>
            </div>
          </template>
        </draggable>
      </div>
    </div>
    <EditorDialog v-model:content="content" v-model:visible="visible" @confirm="onConfirm" />
  </div>
</template>

<script>
import { postMemoPlan } from '@/api/user'
import EditorDialog from '@/components/common/EditorDialog'
import { splitTag } from '@/utils/helper'
import draggable from 'vuedraggable'

export default {
  name: 'memo',
  props: {
    plans: {
      type: Object,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: {}
    },
    userId: {
      type: Number,
      default: -1
    },
    role: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      visible: false,
      content: '',
      focusMemo: '',
      momoState: {
        notUrgentAndNotImportant: [],
        notUrgentAndVeryImportant: [],
        urgentAndNotImportant: [],
        urgentAndVeryImportant: []
      },
      dragging: false
    }
  },
  components: {
    draggable,
    EditorDialog
  },

  watch: {
    '$props.plans': {
      handler: function (data) {
        const deepData = JSON.parse(JSON.stringify(data))
        this.momoState = deepData
      },
      immediate: true
    }
  },
  computed: {
    editoralbe() {
      if (this.role === 'root') {
        return true
      } else if (this.role === 'subRoot' && this.userId !== 3) {
        return true
      } else {
        return this.userId * 1 === this.$store.state.user.userInfo.userId
      }
    }
  },
  methods: {
    parseTags(str) {
      let tag = splitTag(str?.trim())
      tag = tag.slice(0, 1)
      return tag
    },
    openEditor(item) {
      if (!this.editoralbe) {
        return false
      }
      this.item = item
      this.content = item.content
      this.visible = true
    },
    onConfirm(val) {
      this.item.content = val
      const deepData = JSON.parse(JSON.stringify(this.momoState))
      deepData.userId = this.userId * 1
      postMemoPlan(deepData).then((res) => {
        this.$emit('updateUser')
      })
    },
    onFinish(value, item) {
      item.isChecked = value
      const deepData = JSON.parse(JSON.stringify(this.momoState))
      deepData.userId = this.userId * 1
      postMemoPlan(deepData).then((res) => {
        this.$emit('updateUser')
      })
    },
    onMove() {
      const deepData = JSON.parse(JSON.stringify(this.momoState))
      deepData.userId = this.userId * 1
      postMemoPlan(deepData).then((res) => {
        this.$emit('updateUser')
      })
    },
    onDelete(item) {
      if (!this.editoralbe) {
        return false
      }
      item.content = ''
      item.date = ''
      item.isChecked = false
      const deepData = JSON.parse(JSON.stringify(this.momoState))
      deepData.userId = this.userId * 1
      postMemoPlan(deepData).then((res) => {
        this.$emit('updateUser')
      })
    },
    handleChange() {
      const deepData = JSON.parse(JSON.stringify(this.momoState))
      deepData.userId = this.userId * 1
      postMemoPlan(deepData).then((res) => {
        this.$emit('updateUser')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.memo-container {
  font-size: 12px;
  border-top: 1px solid #000;

  .desp {
    height: 30px;
    line-height: 30px;
    background: #e8c8ce;
    border-bottom: 1px solid #000;
  }

  .section {
    width: 100%;
    display: flex;

    .block {
      box-sizing: border-box;
      width: 25%;
      border-right: 1px solid #000;

      &:last-child {
        border-right: none;
      }

      .item {
        height: 26px;
        line-height: 26px;
        display: flex;
        border-bottom: 1px solid #000;

        .index {
          width: 22px;
          height: 100%;
          text-align: center;
          border-right: 1px solid #000;
        }

        .title {
          position: relative;
          flex: 1;
          height: 100%;
          padding: 0 4px;
          border-right: 1px solid #000;
          overflow: hidden;
          display: flex;
          align-items: center;
          .paragraph {
            line-height: 20px;
          }
          .iconWrapper {
            width: 20px;
            height: 20px;
            background: white;
            text-align: center;
            line-height: 20px;
            position: absolute;
            right: 0;
            cursor: pointer;
          }
        }

        .deadline {
          width: 48px;
          overflow: hidden;
          border-right: 1px solid #000;

          ::v-deep .el-date-editor {
            width: 100% !important;
            height: 100% !important;
            border: none;
          }
          ::v-deep .el-input__inner {
            text-align: center;
            padding: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            /* line-height: 21px; */
            height: 100%;
            border: none;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
