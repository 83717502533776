import request from '@/utils/axios'

export function fetchTeam() {
  return request({
    url: `/marketing/teams`,
    method: 'get'
  })
}

export function fetchMaketingDefault(date, id) {
  return request({
    url: `/marketing/default_plan?date=${date}&teamId=${id}`,
    method: 'get'
  })
}

export function postMarketingDefault(data) {
  return request({
    url: `/marketing/default_plan`,
    method: 'post',
    data
  })
}

export function putMarketingDefault(data) {
  return request({
    url: `/marketing/default_plan/block`,
    method: 'put',
    data
  })
}

export function patchMarketingDefault(id, data) {
  return request({
    url: `/marketing/default_plan/${id}`,
    method: 'patch',
    data
  })
}

export function deleteMarketingDefault(id) {
  return request({
    url: `/marketing/default_plan/${id}`,
    method: 'delete'
  })
}

export function fetchMaketing(date, id, type) {
  return request({
    url: `/marketing/plan?date=${date}&teamId=${id}&planType=${type}`,
    method: 'get'
  })
}

export function postMarketing(data) {
  return request({
    url: `/marketing/plan`,
    method: 'post',
    data
  })
}

export function putMarketing(data) {
  return request({
    url: `/marketing/plan/block`,
    method: 'put',
    data
  })
}

export function patchMarketing(id, data) {
  return request({
    url: `/marketing/plan/${id}`,
    method: 'patch',
    data
  })
}

export function deleteMarketing(id) {
  return request({
    url: `/marketing/plan/${id}`,
    method: 'delete'
  })
}

export function fetchMaketingBulletin(teamId) {
  return request({
    url: `/marketing/plan/bulletin?teamId=${teamId}`,
    method: 'get'
  })
}

export function postMarketingBulletin(data) {
  return request({
    url: `/marketing/plan/bulletin`,
    method: 'post',
    data
  })
}
