import request from '@/utils/axios'
import qs from 'qs'
export function loginByUsername(d) {
  const data = {
    username: d.username,
    password: d.password
  }
  return request({
    url: '/login',
    method: 'post',
    header: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: qs.stringify(data)
  })
}

export function changePassword(data) {
  return request({
    url: '/user/change_password',
    method: 'post',
    header: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: qs.stringify(data)
  })
}

// 获取员工列表
export function fetchUserList(date) {
  console.log(date)
  return request({
    url: '/user/get_user_list?date=' + date,
    method: 'get'
  })
}

export function fetchPlanAndReview(id, date) {
  return request({
    url: `/user/plan_and_review?userId=${id}&date=${date}`,
    method: 'get'
  })
}

export function fetchReport(date) {
  return request({
    url: `/user/get_user_report_point?date=${date}`,
    method: 'get'
  })
}

//修改注意事项
export function postReport(data) {
  return request({
    url: '/user/post_user_report_point',
    method: 'post',
    data
  })
}

// 外部メモURL
export function postUserMemoUrl(data) {
  return request({
    url: `/user/post_user_memo_url`,
    method: 'post',
    data
  })
}

// 提交当月计划
export function postUserMonthPlan(data) {
  return request({
    url: `/user/post_user_monthly_plan`,
    method: 'post',
    data
  })
}

// 提交当月总结
export function postUserMonthReview(data) {
  return request({
    url: `/user/post_user_monthly_plan_look_back`,
    method: 'post',
    data
  })
}

// 提交周计划
export function postWeekPlan(data) {
  return request({
    url: `/user/post_user_weekly_plan`,
    method: 'post',
    data
  })
}

// 提交周总结
export function postWeekReview(data) {
  return request({
    url: `/user/post_user_weekly_plan_look_back`,
    method: 'post',
    data
  })
}

// 提交备忘录
export function postMemoPlan(data) {
  return request({
    url: `/user/post_user_memo`,
    method: 'post',
    data
  })
}

// 提交日报计划
export function postDailyPlan(data) {
  return request({
    url: '/user/post_user_daily_plan',
    method: 'post',
    data
  })
}

// 提交日报总结
export function postDailyReviewPlan(data) {
  return request({
    url: '/user/post_user_daily_plan_look_back',
    method: 'post',
    data
  })
}
