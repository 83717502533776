<template>
  <div class="login-container">
    <div class="form-container">
      <div class="title">
        <h2 class="sub-title">One To One Marketing部</h2>
        <h2 class="sub-title" style="margin-top: 12px">パスワード変更</h2>
      </div>
      <el-form ref="formRef" :model="formState" :rules="formRules" label-width="0px" style="margin-top: 60px">
        <el-form-item prop="newPassword">
          <el-input v-model="formState.newPassword" prefix-icon="el-icon-key" placeholder="新しパスワード" show-password></el-input>
        </el-form-item>
        <el-form-item prop="confirmNewPassword">
          <el-input v-model="formState.confirmNewPassword" prefix-icon="el-icon-key" show-password placeholder="パスワード再確認"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button :loading="loading" style="width: 136px;background: #000;color:#fff;outline: none;border:none" type="primary" @click="onSubmit">パスワード変更</el-button>
          <el-button :loading="loading" style="width: 136px;background: #000;color:#fff;outline: none;border:none" type="primary" @click="returnRoot">戻る</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive, ref} from 'vue'
import {useRouter} from 'vue-router'
import {useStore} from "vuex"
import {changePassword} from "@/api/user";
import {removeToken, removeUserInfo} from "@/utils/auth";


export default defineComponent({
  setup: () => {
    const router = useRouter()
    const store = useStore();
    const formRef = ref();
    const loading = ref(false);
    const formState = reactive({
      newPassword: "",
      confirmNewPassword: ""
    })
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(''));
      } else if (value !== formState.newPassword) {
        callback(new Error(''));
      } else {
        callback();
      }
    };
    const formRules = reactive({
      newPassword: [
        {required: true, message: 'パスワードを入力してください', trigger: 'blur'}
      ],
      confirmNewPassword: [
        {required: true, message: 'パスワードを入力してください', trigger: 'blur'},
        { validator: validatePass2, trigger: 'blur' }
      ]
    })
    const onSubmit = () => {
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          changePassword(formState).then(res => {
            loading.value = false
            removeToken()
            removeUserInfo()
            window.location.reload()
          }).catch(e => {
            loading.value = false
          })
        } else {
          return false
        }
      })
    }
    const returnRoot = () => {
      router.push('/')
    }
    return {
      loading,
      formRef,
      formState,
      formRules,
      onSubmit,
      returnRoot,
      validatePass2
    }
  }
})
</script>

<style lang="scss" scoped>
.login-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f7fa;

  .form-container {
    .title {
      color: #000;

    }
    width: 290px;
  }
}

</style>
