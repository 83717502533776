import request from '@/utils/axios'

// 获取meeting title
export function fetchMeetingMinute(date, page, id) {
  const params = id
    ? `date=${date}&pageIndex=${page}&meetingId=${id}`
    : `date=${date}&pageIndex=${page}`
  return request({
    url: `/team/get_meeting_minute?${params}`,
    method: 'get'
  })
}

// 提交meeting title
export function postMeetingTitle(data) {
  return request({
    url: `/team/post_meeting_minute_title`,
    method: 'post',
    data
  })
}

// 提交after meeting
export function postAfterMeetingMinute(data) {
  return request({
    url: `/team/post_after_meeting_minute`,
    method: 'post',
    data
  })
}

// 提交before meeting
export function postBeforeMeetingMinute(data) {
  return request({
    url: `/team/post_before_meeting_minute`,
    method: 'post',
    data
  })
}

// 删除 meeting
export function deleteMeetingMinute(data) {
  return request({
    url: `/team/delete_meeting_minute`,
    method: 'post',
    data
  })
}
