<template>
  <div class="wrapper">
    <el-button
      v-if="isVisibaleButton"
      size="large"
      type="primary"
      style="font-size: 16px; width: 180px; margin-bottom: 10px"
      @click="createLine"
      >追加
    </el-button>
    <div class="table">
      <div class="title">
        <div>デフォルト設定の部分（料金設定等）</div>
        <div>成果の検証［実施後一定期間を経て後のパフォーマンス（評価、判断）］</div>
      </div>
      <div class="contents">
        <div class="defult">
          <div class="labels">期間</div>
          <div class="labels">{{ '変更する点\n（何をどう変える）' }}</div>
          <div class="labels">{{ '目的\n（変更の狙いの何か？）' }}</div>
          <div class="labels">{{ '具体的変更内容\n（コストが発生する場合のコストも記載）' }}</div>
        </div>
        <div class="result">
          <div class="labels">期間</div>
          <div class="labels">{{ '成果や効果\n（コストが発生した場合のコストも記載）' }}</div>
          <div class="labels">評価</div>
          <div class="labels">{{ '問題点\n課題点' }}</div>
          <div class="labels">{{ '今後に繋げる\n改善点等' }}</div>
          <div class="labels" style="border-right: none">
            {{ '学びや\n気付き' }}
          </div>
        </div>
      </div>
      <div class="contents">
        <div class="defult">
          <div>
            <div
              :class="[
                'values',
                'date-wrapper',
                `values-${backgroundColorState(item, index)}`,
                index === results.length - 1 ? 'no-border-bottom' : ''
              ]"
              v-for="(item, index) in results"
              :key="'planStartDt' + item.id"
            >
              <div class="start">
                <div class="date-text">
                  {{ item.planStartDt ? dateText(item.planStartDt) : '開始時間' }}
                </div>
                <el-date-picker
                  v-model="item.planStartDt"
                  type="datetime"
                  format="YYYY-MM-DD HH:mm"
                  :disabled="item.isPlanBlocked"
                  :clearable="false"
                  :class="[dateTimePiker]"
                  @change="(value) => onUpdatePlan(item)"
                >
                </el-date-picker>
              </div>
              <div class="date-middle">~</div>
              <div class="end">
                <div class="date-text">
                  {{ item.planEndDt ? dateText(item.planEndDt) : '終了時間' }}
                </div>
                <el-date-picker
                  v-model="item.planEndDt"
                  type="datetime"
                  format="YYYY-MM-DD HH:mm"
                  :disabled="item.isPlanBlocked"
                  :clearable="false"
                  :class="[dateTimePiker]"
                  @change="(value) => onUpdatePlan(item)"
                >
                </el-date-picker>
              </div>
            </div>
          </div>
          <div>
            <div
              :class="[
                'values',
                `values-${backgroundColorState(item, index)}`,
                index === results.length - 1 ? 'no-border-bottom' : ''
              ]"
              v-for="(item, index) in results"
              :key="'changePoint' + item.id"
            >
              <table-cell
                top
                rowHeight="16px"
                :maxLine="5"
                :hasPermission="true"
                :freeze="item.isPlanBlocked"
                :goal="item.changePoint"
                :role="role"
                :teamPossesion="true"
                @update="
                  (value) => {
                    item.changePoint = value
                    onUpdatePlan(item)
                  }
                "
              />
            </div>
          </div>
          <div>
            <div
              :class="[
                'values',
                `values-${backgroundColorState(item, index)}`,
                index === results.length - 1 ? 'no-border-bottom' : ''
              ]"
              v-for="(item, index) in results"
              :key="'purpose' + item.id"
            >
              <table-cell
                top
                rowHeight="16px"
                :maxLine="5"
                :hasPermission="true"
                :freeze="item.isPlanBlocked"
                :goal="item.purpose"
                :role="role"
                :teamPossesion="true"
                @update="
                  (value) => {
                    item.purpose = value
                    onUpdatePlan(item)
                  }
                "
              />
            </div>
          </div>
          <div>
            <div
              :class="[
                'values',
                `values-${backgroundColorState(item, index)}`,
                index === results.length - 1 ? 'no-border-bottom' : ''
              ]"
              v-for="(item, index) in results"
              :key="'concrete' + item.id"
              style="position: relative; border-right: 1px solid #000"
            >
              <table-cell
                top
                rowHeight="16px"
                :maxLine="3"
                :hasPermission="true"
                :freeze="item.isPlanBlocked"
                :goal="item.concrete"
                :role="role"
                :teamPossesion="true"
                @update="
                  (value) => {
                    item.concrete = value
                    onUpdatePlan(item)
                  }
                "
              />
              <div class="post-info">
                <div
                  style="cursor: pointer; margin-right: 4px"
                  v-if="!item.isPlanBlocked"
                  @click.stop="confirmDelete(item.id)"
                >
                  <i class="el-icon-delete"></i>
                </div>
                <div
                  v-if="item.isPlanBlocked"
                  style="
                    border: 1px dashed darkslategrey;
                    line-height: 12px;
                    padding: 2px;
                    background-color: lightcyan;
                  "
                >
                  {{
                    `${item.planBlockeUsername?.slice(0, 3) ?? ''} ${formatBlockAt(
                      item.planBlockedAt
                    )}`
                  }}
                </div>
                <div v-else>
                  <el-button size="mini" type="primary" @click.stop="confirmSubmit(item.id, 'plan')"
                    >報告</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="result">
          <div>
            <div
              :class="[
                'values',
                'date-wrapper',
                `values-${backgroundColorState(item, index)}`,
                index === results.length - 1 ? 'no-border-bottom' : ''
              ]"
              v-for="(item, index) in results"
              :key="'resultStartDt' + item.id"
            >
              <div class="start">
                <div class="date-text">
                  {{ item.resultStartDt ? dateText(item.resultStartDt) : '開始時間' }}
                </div>
                <el-date-picker
                  v-model="item.resultStartDt"
                  type="datetime"
                  format="YYYY-MM-DD HH:mm"
                  :disabled="item.isResultBlocked"
                  :clearable="false"
                  :class="[dateTimePiker]"
                  @change="(value) => onUpdatePlan(item)"
                >
                </el-date-picker>
              </div>
              <div class="date-middle">~</div>
              <div class="end">
                <div class="date-text">
                  {{ item.resultEndDt ? dateText(item.resultEndDt) : '終了時間' }}
                </div>
                <el-date-picker
                  v-model="item.resultEndDt"
                  type="datetime"
                  format="YYYY-MM-DD HH:mm"
                  :disabled="item.isResultBlocked"
                  :clearable="false"
                  :class="[dateTimePiker]"
                  @change="(value) => onUpdatePlan(item)"
                >
                </el-date-picker>
              </div>
            </div>
          </div>
          <div>
            <div
              :class="[
                'values',
                `values-${backgroundColorState(item, index)}`,
                index === results.length - 1 ? 'no-border-bottom' : ''
              ]"
              v-for="(item, index) in results"
              :key="'achievementAndEffect' + item.id"
            >
              <table-cell
                top
                rowHeight="16px"
                :maxLine="5"
                :hasPermission="true"
                :freeze="item.isResultBlocked"
                :goal="item.achievementAndEffect"
                :role="role"
                :teamPossesion="true"
                @update="
                  (value) => {
                    item.achievementAndEffect = value
                    onUpdatePlan(item)
                  }
                "
              />
            </div>
          </div>
          <div>
            <div
              :class="[
                'values',
                `values-${backgroundColorState(item, index)}`,
                index === results.length - 1 ? 'no-border-bottom' : ''
              ]"
              v-for="(item, index) in results"
              :key="'evaluation' + item.id"
            >
              <table-cell
                top
                rowHeight="16px"
                :maxLine="5"
                :hasPermission="true"
                :freeze="item.isResultBlocked"
                :goal="item.evaluation"
                :role="role"
                :teamPossesion="true"
                @update="
                  (value) => {
                    item.evaluation = value
                    onUpdatePlan(item)
                  }
                "
              />
            </div>
          </div>
          <div>
            <div
              :class="[
                'values',
                `values-${backgroundColorState(item, index)}`,
                index === results.length - 1 ? 'no-border-bottom' : ''
              ]"
              v-for="(item, index) in results"
              :key="'problem' + item.id"
            >
              <table-cell
                top
                rowHeight="16px"
                :maxLine="5"
                :hasPermission="true"
                :freeze="item.isResultBlocked"
                :goal="item.problem"
                :role="role"
                :teamPossesion="true"
                @update="
                  (value) => {
                    item.problem = value
                    onUpdatePlan(item)
                  }
                "
              />
            </div>
          </div>
          <div>
            <div
              :class="[
                'values',
                `values-${backgroundColorState(item, index)}`,
                index === results.length - 1 ? 'no-border-bottom' : ''
              ]"
              v-for="(item, index) in results"
              :key="'toBeImproved' + item.id"
            >
              <table-cell
                top
                rowHeight="16px"
                :maxLine="5"
                :hasPermission="true"
                :freeze="item.isResultBlocked"
                :goal="item.toBeImproved"
                :role="role"
                :teamPossesion="true"
                @update="
                  (value) => {
                    item.toBeImproved = value
                    onUpdatePlan(item)
                  }
                "
              />
            </div>
          </div>
          <div>
            <div
              :class="[
                'values',
                `values-${backgroundColorState(item, index)}`,
                index === results.length - 1 ? 'no-border-bottom' : ''
              ]"
              style="border-right: none; position: relative"
              v-for="(item, index) in results"
              :key="'learned' + item.id"
            >
              <table-cell
                top
                rowHeight="16px"
                :maxLine="3"
                :hasPermission="true"
                :freeze="item.isResultBlocked"
                :goal="item.learned"
                :role="role"
                :teamPossesion="true"
                @update="
                  (value) => {
                    item.learned = value
                    onUpdatePlan(item)
                  }
                "
              />
              <div class="post-info">
                <div
                  v-if="item.isResultBlocked"
                  style="
                    border: 1px dashed darkslategrey;
                    line-height: 12px;
                    padding: 2px;
                    background-color: lightcyan;
                  "
                >
                  {{
                    `${item.resultBlockUsername?.slice(0, 3) ?? ''} ${formatBlockAt(
                      item.resultBlockedAt
                    )}`
                  }}
                </div>
                <div v-else>
                  <el-button
                    size="mini"
                    type="primary"
                    @click.stop="confirmSubmit(item.id, 'result')"
                    >報告</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)
import tableCell from '@/components/common/table-cell'
import { computed, defineComponent, ref, toRef, watch } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import {
  postMarketingDefault,
  patchMarketingDefault,
  deleteMarketingDefault,
  putMarketingDefault
} from '@/api/marketing'
import { splitTag } from '@/utils/helper'
export default defineComponent({
  name: 'Results',
  props: {
    responseData: {
      type: Array,
      require: true
    },
    role: {
      type: String,
      default: ''
    },
    currentTeam: {
      type: Number,
      default: null
    },
    date: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dateTimePiker: 'marketing-date-time-piker'
    }
  },
  components: {
    tableCell
  },
  methods: {
    parseTags(str) {
      let tag = splitTag(str?.trim())
      tag = tag.slice(0, 1)
      return tag
    },
    dateText(time) {
      return `${dayjs(time).format('YYYY年MM月DD日')}\n${dayjs(time).format('HH:mm')}`
    },
    formatBlockAt(time) {
      return dayjs(time).format('MM/DD HH:mm')
    },
    backgroundColorState(item) {
      if (
        dayjs(item.planStartDt).diff(dayjs()) <= 0 &&
        dayjs(item.planEndDt).diff(dayjs()) >= 0 &&
        item.isPlanBlocked
      )
        return '2'
      if (item.isPlanBlocked) return '1'
      return '3'
    }
  },
  setup: (props, { emit }) => {
    const responseDataCopy = toRef(props, 'responseData')
    const results = ref([])
    const confirmSubmit = (id, toBlock) => {
      ElMessageBox.confirm(
        '入力内容でご報告よろしいですか？報告後、該当内容は再編集出来ませんので、ご注意ください。',
        'ご確認',
        {
          confirmButtonText: '確定',
          cancelButtonText: 'キャンセル',
          type: 'primary'
        }
      )
        .then(() => {
          onPutPlan(id, toBlock)
        })
        .catch((e) => {
          console.log(e)
        })
    }

    const confirmDelete = (id) => {
      ElMessageBox.confirm('計画を削除してもよろしいですか？', 'ご確認', {
        confirmButtonText: '確定',
        cancelButtonText: 'キャンセル',
        type: 'primary'
      })
        .then(() => {
          deleteMarketingDefault(id).then(() => {
            emit('updateMaketing')
          })
        })
        .catch((e) => {
          console.log(e)
        })
    }

    const onUpdatePlan = (item) => {
      const parms = {
        planStartDt: item.planStartDt ? dayjs(item.planStartDt).utc().format() : null,
        planEndDt: item.planEndDt ? dayjs(item.planEndDt).utc().format() : null,
        resultStartDt: item.resultStartDt ? dayjs(item.resultStartDt).utc().format() : null,
        resultEndDt: item.resultEndDt ? dayjs(item.resultEndDt).utc().format() : null
      }
      patchMarketingDefault(item.id, { ...item, ...parms }).then(() => {
        emit('updateMaketing')
      })
    }

    const onPutPlan = (id, toBlock) => {
      putMarketingDefault({ id, toBlock }).then(() => {
        emit('updateMaketing')
      })
    }

    const createLine = () => {
      let data = {
        teamId: props.currentTeam,
        planStartDt: `${dayjs().set('hour', -9).set('minute', 0).set('seconds', 0).format()}`,
        planEndDt: `${dayjs().set('hour', 14).set('minute', 59).set('seconds', 59).format()}`,
        resultStartDt: null,
        resultEndDt: null
      }

      postMarketingDefault(data).then(() => {
        emit('updateMaketing')
      })
    }

    watch(
      responseDataCopy,
      (data) => {
        data.map((item) => {
          item.planStartDt = item.planStartDt ? dayjs(item.planStartDt).format() : ''
          item.planEndDt = item.planEndDt ? dayjs(item.planEndDt).format() : ''
          item.resultStartDt = item.resultStartDt ? dayjs(item.resultStartDt).format() : ''
          item.resultEndDt = item.resultEndDt ? dayjs(item.resultEndDt).format() : ''
        })

        results.value = data
      },
      {
        immediate: true
      }
    )

    const dateCopy = toRef(props, 'date')
    const isVisibaleButton = ref(false)
    watch(
      dateCopy,
      (data) => {
        isVisibaleButton.value = dayjs(data).isSame(dayjs(), 'month')
      },
      {
        immediate: true
      }
    )

    return {
      results,
      createLine,
      onUpdatePlan,
      confirmSubmit,
      confirmDelete,
      isVisibaleButton
    }
  }
})
</script>

<style lang="scss" scoped>
.wrapper {
  width: calc(100vw - 40px);
  overflow: auto;
  margin-top: 10px;
  padding-bottom: 40px;
  .table {
    min-width: 1200px;
    border: 2px solid #000;
    border-bottom: 2px solid #000;
    margin-bottom: 10px;
    .contents {
      display: grid;
      grid-template-columns: 50% 50%;
      max-height: 600px;
      overflow-y: overlay;
      .values {
        border-right: 1px dashed #000;
        border-bottom: 1px dashed #000;
        height: 90px;
        font-size: 14px;
        box-sizing: border-box;
      }
      .post-info {
        position: absolute;
        right: 10px;
        bottom: 4px;
        display: flex;
        align-items: flex-end;
      }
      .values-1 {
        background: white;
      }
      .values-2 {
        background: #defdda;
      }
      .values-3 {
        background: #fefb94;
      }
      .values-4 {
        background: #fefb94;
      }
      .no-border-bottom {
        border-bottom: none;
      }
      .date-wrapper {
        display: grid;
        grid-template-rows: 40px 10px 40px;
        justify-items: center;
        align-items: center;

        .start,
        .end {
          position: relative;
        }
        .date-middle {
          text-align: center;
        }
      }
    }
    .title {
      display: grid;
      grid-template-columns: 50% 50%;
      border-bottom: 2px solid #000;
      font-size: 12px;
      font-weight: bold;
      text-align: center;
      background: #ebeef5;
      > div:first-child {
        padding: 4px 0;
        border-right: 1px solid #000;
        background: #00d659;
      }
      > div:last-child {
        padding: 4px 0;
        background: #fecf28;
      }
    }
    .defult {
      display: grid;
      grid-template-columns: 100px calc(40% - 40px) calc(20% - 20px) calc(40% - 40px);
      .labels {
        background: #00d659;
      }
    }
    .result {
      display: grid;
      grid-template-columns:
        100px calc(35% - 35px) calc(15% - 15px) calc(15% - 15px) calc(15% - 15px)
        calc(20% - 20px);
      .labels {
        background: #fecf28;
      }
    }
    .labels {
      min-height: 60px;
      border-right: 1px solid #000;
      border-bottom: 1px solid #000;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: bold;
      font-size: 12px;
      white-space: pre-wrap;
      background: #ebeef5;
    }
    .date-text {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      white-space: pre-wrap;
      text-align: center;
    }
  }
}
* {
  box-sizing: border-box;
}
</style>
