<template>
  <div :class="[meetingGlobal, 'meeting-wrapper']" :id="'meeting' + meeting.meetingId">
    <div class="meeting" style="border: 2px solid #c0c0c0; border-bottom: none; margin-top: 24px">
      <div class="link-wrapper" @click.stop="copyLink(meeting.meetingId)">
        <i :class="['el-icon-link']" @mouseenter="isHover = true" @mouseleave="onLeave"></i
        ><span v-if="isHover" :class="['tips', { copiedtips: isCopied }]">{{
          isCopied ? 'リンクをコピーしました' : 'このMTG・会議のリンクをコピーする'
        }}</span>
      </div>
      <div
        v-if="!meeting.before.isChanged && !meeting.after.isChanged"
        style="position: absolute; right: 0; top: -24px; cursor: pointer"
        @click.stop="confirmDelete"
      >
        <i class="el-icon-delete"></i>
      </div>

      <div class="title" style="background: #ebeef5">事 前 周 知 事 項</div>
      <div class="contents-before">
        <div>
          <div class="label" style="flex-direction: column">
            <div style="font-size: 20px;">MTG・会議</div>
            <div>
              No.<span style="font-size: 20px; padding-left: 4px; margin-bottom: 4px">{{
                meeting.meetingId
              }}</span>
            </div>
          </div>
          <div class="content op-wrapper">
            <div class="meeting-info">
              <div class="op-label">類別</div>
              <div class="op time-op">
                <div class="type">
                  <div class="date-text">
                    {{
                      meetingTypeOptions.find((item) => item.id === meeting.before.meetingType)
                        ?.name
                    }}
                  </div>
                  <el-select
                    :disabled="meeting.before.isChanged"
                    v-model="meeting.before.meetingType"
                    @change="(value) => onUpdateBeforeMeeting(value, 'meetingType')"
                    placeholder=" "
                    :class="[meetingMultipleSelect]"
                  >
                    <el-option
                      v-for="item in meetingTypeOptions"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="meeting-name">
                  <div class="label" style='font-size: 12px'>会議名</div>
                  <div>
                    <table-cell
                      :maxLine="2"
                      :freeze="meeting.before.isChanged"
                      :goal="meeting.before.meetingPurpose"
                      :role="role"
                      :teamPossesion="true"
                      :hasPermission="true"
                      @update="
                        (value) => {
                          onUpdateBeforeMeeting(value, 'meetingPurpose')
                        }
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="meeting-info">
              <div class="op-label">日付</div>
              <div class="op">
                <div class="date-text">
                  {{ dateText }}
                </div>
                <el-date-picker
                  :disabled="meeting.before.isChanged"
                  v-model="meeting.before.date"
                  :class="[meetingDayPicker]"
                  :clearable="false"
                  @change="(value) => onUpdateBeforeMeeting(value, 'date')"
                >
                </el-date-picker>
              </div>
            </div>
            <div class="meeting-info">
              <div class="op-label">開始時刻と終了時刻</div>
              <div class="op time-op">
                <div :class="['start', { isChanged: meeting.before.isChanged }]">
                  <span>予定</span
                  ><el-time-picker
                    :disabled="meeting.before.isChanged"
                    is-range
                    v-model="meeting.before.startTimeAndEndTime"
                    range-separator="~"
                    start-placeholder="開始"
                    end-placeholder="終了"
                    placeholder="选择时间范围"
                    prefix-icon="none"
                    format="HH:mm"
                    @change="(value) => onUpdateBeforeMeeting(value, 'startTimeAndEndTime')"
                    :clearable="false"
                    :class="[meetingTimeRangePicker]"
                  >
                  </el-time-picker>
                </div>
                <div :class="['end', { isChanged: meeting.after.isChanged }]">
                  <span>実際</span
                  ><el-time-picker
                    :disabled="meeting.after.isChanged"
                    is-range
                    v-model="meeting.after.startTimeAndEndTime"
                    range-separator="~"
                    start-placeholder="開始"
                    end-placeholder="終了"
                    placeholder="选择时间范围"
                    prefix-icon="none"
                    format="HH:mm"
                    @change="(value) => onUpdateAfterMeeting(value, 'startTimeAndEndTime')"
                    :clearable="false"
                    :class="[meetingTimeRangePicker]"
                  >
                  </el-time-picker>
                </div>
              </div>
            </div>
            <div class="meeting-info">
              <div class="op-label" style="font-size: 12px">
                {{ '場所\n（Webの場合、「Web」と記載）' }}
              </div>
              <div class="op">
                <table-cell
                  :freeze="meeting.before.isChanged"
                  :goal="meeting.before.location"
                  :role="role"
                  :teamPossesion="true"
                  :hasPermission="true"
                  center
                  @update="
                    (value) => {
                      onUpdateBeforeMeeting(value, 'location')
                    }
                  "
                />
              </div>
            </div>
            <div class="meeting-info">
              <div class="op-label">出席者</div>
              <div class="op">
                <table-cell
                  :freeze="meeting.before.isChanged"
                  :goal="meeting.before.member"
                  :role="role"
                  :teamPossesion="true"
                  :hasPermission="true"
                  center
                  @update="
                    (value) => {
                      onUpdateBeforeMeeting(value, 'member')
                    }
                  "
                />
                <!-- <div class="multiple-text">
                  {{ value2 }}
                </div>
                <el-select
                  v-model="value2"
                  multiple
                  placeholder=" "
                  :class="[meetingMultipleSelect]"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select> -->
              </div>
            </div>
            <div class="meeting-info" style="border-right: none">
              <div class="op-label" style="font-size: 14px">{{ '会議のオーナー・記述者' }}</div>
              <div class="op time-op">
                <div class="organizer" style='border-bottom: 1px dashed #c0c0c0'>
                  <div class="label" style='font-size: 10px; padding: 0'>{{ 'オーナー\n（主催者）' }}</div>
                  <div>
                    <table-cell
                      :freeze="meeting.before.isChanged"
                      :goal="meeting.before.organizer"
                      :role="role"
                      :teamPossesion="true"
                      :hasPermission="true"
                      center
                      @update="
                        (value) => {
                          onUpdateBeforeMeeting(value, 'organizer')
                        }
                      "
                    />
                  </div>
                </div>
                <div class="organizer">
                  <div class="label" style='font-size: 10px; padding: 0'>{{ '記述者\n（オーナーが書く）' }}</div>
                  <div>
                    <table-cell
                      :freeze="meeting.before.isChanged"
                      :goal="meeting.before.writer"
                      :role="role"
                      :teamPossesion="true"
                      :hasPermission="true"
                      center
                      @update="
                        (value) => {
                          onUpdateBeforeMeeting(value, 'writer')
                        }
                      "
                    /></div>
                </div>
                <!-- <div class="multiple-text">
                  {{ value2 }}
                </div>
                <el-select v-model="value2" placeholder=" " :class="[meetingMultipleSelect]">
                  <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select> -->
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="label" style="font-size: 14px">
            議題
          </div>
          <div class="content">
            <table-cell
              :freeze="meeting.before.isChanged"
              :goal="defaultTheme(meeting.before.theme)"
              :role="role"
              :teamPossesion="true"
              :hasPermission="true"
              @update="
                (value) => {
                  onUpdateBeforeMeeting(value, 'theme')
                }
              "
            />
          </div>
        </div>
        <div>
          <div class="label" style="font-size: 14px">{{ '開催の目的' }}</div>
          <div class="content purpose-content">
            <div class="op purpose-op">
              <el-checkbox-group
                v-model="meeting.before.meetingPurposeType"
                :disabled="meeting.before.isChanged"
                :class="[meetingMultipleSelectM]"
                @change="(value) => onUpdateBeforeMeeting(value, 'meetingPurposeType')"
              >
                <el-checkbox
                  v-for="item in multiPurposeTypeOptions"
                  :key="item.id"
                  :label="item.name"
                />
              </el-checkbox-group>
              <!-- <div class="multiple-text">
                {{
                  purposeTypeOptions.find((item) => item.id === meeting.before.meetingPurposeType)
                    ?.name
                }}
              </div>
              <el-select
                :disabled="meeting.before.isChanged"
                v-model="meeting.before.meetingPurposeType"
                @change="(value) => onUpdateBeforeMeeting(value, 'meetingPurposeType')"
                placeholder=" "
                :class="[meetingMultipleSelect]"
              >
                <el-option
                  v-for="item in purposeTypeOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select> -->
            </div>
            <div class="label" style="font-size: 14px;">
              {{ '目的を具体的に記述' }}
            </div>
            <div class="purpose">
              <div class="tabel-title"></div>
              <div>
                <table-cell
                  :freeze="meeting.before.isChanged"
                  :goal="meeting.before.expectedResult"
                  :role="role"
                  :teamPossesion="true"
                  :hasPermission="true"
                  @update="
                    (value) => {
                      onUpdateBeforeMeeting(value, 'expectedResult')
                    }
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="label" style="font-size: 14px">{{ '事前共有事項' }}</div>
          <div class="content">
            <!-- <div class="tabel-title">【開催の前後で「何をどのように変えたいのか？」】</div> -->
            <table-cell
              :freeze="meeting.before.isChanged"
              :goal="meeting.before.meetingReason"
              :role="role"
              :teamPossesion="true"
              :hasPermission="true"
              @update="
                (value) => {
                  onUpdateBeforeMeeting(value, 'meetingReason')
                }
              "
            />
          </div>
        </div>
        <div>
          <div class="label" style="font-size: 14px">
            {{ 'ゴールの設定' }}
          </div>
          <div class="content">
            <div class="tabel-title">
              【何を決めるのか明確に書く】
            </div>
            <table-cell
              style='justify-content: flex-start'
              :freeze="meeting.before.isChanged"
              :goal="meeting.before.meetingGoal"
              :role="role"
              :maxLine='2'
              :teamPossesion="true"
              :hasPermission="true"
              @update="
                (value) => {
                  onUpdateBeforeMeeting(value, 'meetingGoal')
                }
              "
            />
          </div>
        </div>
        <div>
          <div class="label" style="font-size: 14px">
            {{ '参加者の\n事前準備事項' }}
          </div>
          <div class="content">
            <table-cell
              :freeze="meeting.before.isChanged"
              :goal="meeting.before.advanceToDo"
              :role="role"
              :teamPossesion="true"
              :hasPermission="true"
              @update="
                (value) => {
                  onUpdateBeforeMeeting(value, 'advanceToDo')
                }
              "
            />
          </div>
        </div>
        <div>
          <div class="label" style="font-size: 14px">{{ 'その他のアジェンダ' }}</div>
          <div class="content">
            <table-cell
              :freeze="meeting.before.isChanged"
              :goal="meeting.before.additionalInformation"
              :role="role"
              :teamPossesion="true"
              :hasPermission="true"
              @update="
                (value) => {
                  onUpdateBeforeMeeting(value, 'additionalInformation')
                }
              "
            />
          </div>
        </div>
        <div style="border-bottom: none; position: relative">
          <div class="label" style="font-size: 14px">
            {{ 'メ　　　モ' }}
          </div>
          <div class="content">
            <table-cell
              :freeze="meeting.before.isChanged"
              :goal="meeting.before.howToProceed"
              :role="role"
              :teamPossesion="true"
              :hasPermission="true"
              @update="
                (value) => {
                  onUpdateBeforeMeeting(value, 'howToProceed')
                }
              "
            />
          </div>
          <div class="post-info">
            <div
              v-if="meeting.before.updatedAt"
              style="
                border: 1px dashed darkslategrey;
                line-height: 12px;
                padding: 2px;
                margin-bottom: 2px;
                background-color: lightcyan;
              "
            >
              {{ meeting.before.updatedAt }}
            </div>
            <div v-else>
              <el-button
                size="mini"
                type="primary"
                style="position: absolute; right: 4px; bottom: 4px"
                @click.stop="confirmSubmit('before')"
                >報告</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="meeting">
      <div class="title" style="background: #cae2fc; border-right: 2px solid #97c4fa">
        開 催 後 記 入 事 項
      </div>
      <div class="contents-after">
        <div>
          <div class="label" style="font-size: 14px">
            {{ '決定事項' }}
          </div>
          <div class="content">
            <table-cell
              :freeze="meeting.after.isChanged"
              :goal="meeting.after.decisions"
              :role="role"
              :teamPossesion="true"
              :hasPermission="true"
              @update="
                (value) => {
                  onUpdateAfterMeeting(value, 'decisions')
                }
              "
            />
          </div>
        </div>
        <div>
          <div class="label" style="font-size: 14px">
            {{ '未決定事項' }}
          </div>
          <div class="content">
            <table-cell
              :freeze="meeting.after.isChanged"
              :goal="meeting.after.toDo"
              :role="role"
              :teamPossesion="true"
              :hasPermission="true"
              @update="
                (value) => {
                  onUpdateAfterMeeting(value, 'toDo')
                }
              "
            />
          </div>
        </div>
        <div style="border-bottom: none; position: relative">
          <div class="label" style="font-size: 14px">メモ・備忘録</div>
          <div class="content">
            <table-cell
              :freeze="meeting.after.isChanged"
              :goal="meeting.after.memo"
              :role="role"
              :teamPossesion="true"
              :hasPermission="true"
              @update="
                (value) => {
                  onUpdateAfterMeeting(value, 'memo')
                }
              "
            />
          </div>
          <div class="post-info">
            <div
              v-if="meeting.after.updatedAt"
              style="
                border: 1px dashed darkslategrey;
                line-height: 12px;
                padding: 2px;
                margin-bottom: 2px;
                background-color: lightcyan;
              "
            >
              {{ meeting.after.updatedAt }}
            </div>
            <div v-else>
              <el-button
                size="mini"
                type="primary"
                style="position: absolute; right: 4px; bottom: 4px"
                @click.stop="confirmSubmit('after')"
                >報告</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <EditorDialog v-model:visible="visible" v-model:content="content" @confirm="onConfirm" />
  </div>
</template>

<script>
import dayjs from 'dayjs'
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)
import tableCell from '@/components/common/table-cell'
import { computed, defineComponent, ref, toRef, watch } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import { postBeforeMeetingMinute, postAfterMeetingMinute, deleteMeetingMinute } from '@/api/meeting'
import { splitTag } from '@/utils/helper'
export default defineComponent({
  name: 'Meeting',
  props: {
    meetingData: {
      type: Object,
      require: true
    },
    role: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      meetingDayPicker: 'meeting-day-picker',
      meetingTimeRangePicker: 'meeting-time-range-picker',
      meetingMultipleSelect: 'meeting-multiple-select',
      meetingMultipleSelectM: 'meeting-multiple-select-multi',
      meetingGlobal: 'meeting-global',
      meetingTypeOptions: [
        { id: '0', name: '定例' },
        { id: '1', name: '臨時' },
        { id: '2', name: '不定期' },
        { id: '3', name: 'スポット' },
        { id: '4', name: '外交' },
        { id: '5', name: '来客' },
        { id: '6', name: '会食' }
      ],
      purposeTypeOptions: [
        { id: '0', name: '--------' },
        { id: '1', name: '決める会議(問題解決・方針決定・対応決定)' },
        { id: '2', name: '情報共有(報告・連絡)する会議' },
        { id: '3', name: 'アイデアを出す会議' }
      ],
      multiPurposeTypeOptions: [
        { id: '1', name: '意思決定(方針・対応)' },
        { id: '4', name: '問題解決' },
        { id: '2', name: '情報共有' },
        { id: '5', name: '報告・連絡・相談' },
        { id: '3', name: 'アイデアを出す（ブレスト）' },
        { id: '6', name: '業務進捗' }
      ]
    }
  },
  components: {
    tableCell
  },
  methods: {
    parseTags(str) {
      let tag = splitTag(str?.trim())
      tag = tag.slice(0, 1)
      return tag
    },
    defaultTheme(str) {
      if (str) return str
      return  ''
      // return '<p>議題：</p>'
    }
  },
  setup: (props, { emit }) => {
    const dateText = computed(() => {
      let weekJa = '日'
      switch (dayjs(meeting.value.before.date).day()) {
        case 0:
          weekJa = '日'
          break
        case 1:
          weekJa = '月'
          break
        case 2:
          weekJa = '火'
          break
        case 3:
          weekJa = '水'
          break
        case 4:
          weekJa = '木'
          break
        case 5:
          weekJa = '金'
          break
        case 6:
          weekJa = '土'
          break
        default:
          ''
          break
      }
      return `${dayjs(meeting.value.before.date).format('YYYY年MM月DD日')}\n(${weekJa}曜日)`
    })
    const meetingDataCopy = toRef(props, 'meetingData')
    const meeting = ref({})
    const isHover = ref(false)
    const isCopied = ref(false)
    const formatDate = (value) => (value ? dayjs.tz(value, 'Asia/Tokyo').format('YYYY/MM/DD') : '')
    const confirmSubmit = (key) => {
      if (
        key === 'after' &&
        (!meeting.value.before.startTimeAndEndTime[0] ||
          !meeting.value.before.startTimeAndEndTime[1] ||
          !meeting.value.after.startTimeAndEndTime[0] ||
          !meeting.value.after.startTimeAndEndTime[1])
      ) {
        ElMessage.error('実際の開始時刻と終了時刻をご入力してください')
      } else {
        ElMessageBox.confirm(
          '入力内容でご報告よろしいですか？報告後、該当内容は再編集出来ませんので、ご注意ください。',
          'ご確認',
          {
            confirmButtonText: '確定',
            cancelButtonText: 'キャンセル',
            type: 'primary'
          }
        )
          .then(() => {
            key === 'after'
              ? onUpdateAfterMeeting(true, 'isAutoLock')
              : onUpdateBeforeMeeting(true, 'isAutoLock')
          })
          .catch((e) => {
            console.log(e)
          })
      }
    }

    const onLeave = () => {
      isHover.value = false
      isCopied.value = false
    }

    const copyLink = () => {
      if (navigator.clipboard) {
        isCopied.value = true
        navigator.clipboard.writeText(
          `${process.env.VUE_APP_ROUTE_URL}#/meeting?id=${meeting.value.meetingId}`
        )
      }
    }

    const confirmDelete = () => {
      ElMessageBox.confirm('会議を削除してもよろしいですか？', 'ご確認', {
        confirmButtonText: '確定',
        cancelButtonText: 'キャンセル',
        type: 'primary'
      })
        .then(() => {
          deleteMeetingMinute({ meetingId: meeting.value.meetingId }).then(() => {
            emit('updateMeeting')
          })
        })
        .catch((e) => {
          console.log(e)
        })
    }

    const onUpdateBeforeMeeting = (value, key) => {
      const {
        date,
        startTime,
        endTime,
        location,
        organizer,
        writer,
        member,
        theme,
        meetingPurposeType,
        meetingType,
        meetingPurpose,
        expectedResult,
        meetingReason,
        meetingGoal,
        advanceToDo,
        additionalInformation,
        startTimeAndEndTime,
        howToProceed
      } = meeting.value.before

      const whiteList = ['date', 'startTimeAndEndTime', 'meetingPurposeType', 'meetingType']

      let data = {
        meetingId: meeting.value.meetingId,
        date: dayjs(date).format('YYYY-MM-DD'),
        startTime: dayjs(startTime).format('YYYY-MM-DD'),
        endTime: dayjs(endTime).format('YYYY-MM-DD'),
        location,
        organizer,
        writer,
        member,
        theme,
        meetingPurposeType,
        meetingType,
        meetingPurpose,
        expectedResult,
        meetingReason,
        meetingGoal,
        advanceToDo,
        additionalInformation,
        startTimeAndEndTime,
        howToProceed,
        isAutoLock: false
      }

      !whiteList.includes(key) && (data[key] = value)

      console.log(data)

      postBeforeMeetingMinute(data).then(() => {
        emit('updateMeeting')
      })
    }

    const onUpdateAfterMeeting = (value, key) => {
      const { decisions, toDo, memo, startTimeAndEndTime } = meeting.value.after
      const whiteList = ['startTimeAndEndTime']
      let data = {
        meetingId: meeting.value.meetingId,
        decisions,
        toDo,
        startTimeAndEndTime,
        memo,
        isAutoLock: false
      }

      !whiteList.includes(key) && (data[key] = value)

      postAfterMeetingMinute(data).then(() => {
        emit('updateMeeting')
      })
    }

    watch(
      meetingDataCopy,
      (data) => {
        data.before.startTimeAndEndTime = data.before.startTimeAndEndTime.map((item) =>
          item ? dayjs(item).format() : new Date()
        )
        data.after.startTimeAndEndTime = data.after.startTimeAndEndTime.map((item) =>
          item ? dayjs(item).format() : new Date()
        )
        meeting.value = data
      },
      {
        immediate: true
      }
    )

    return {
      dateText,
      meeting,
      formatDate,
      onUpdateBeforeMeeting,
      onUpdateAfterMeeting,
      confirmSubmit,
      copyLink,
      isCopied,
      onLeave,
      isHover,
      confirmDelete
    }
  }
})
</script>

<style lang="scss" scoped>
.meeting-wrapper {
  max-width: calc(100vw - 40px);
  position: relative;
  display: grid;
  grid-template-rows: auto auto;
  overflow: auto;
  margin-bottom: 60px;
  .meeting {
    width: 100%;
    min-width: 1124px;
    position: relative;
    display: grid;
    grid-template-columns: 30px 1fr;
    border: 2px solid #97c4fa;
    box-sizing: border-box;
    .title {
      box-sizing: border-box;
      padding: 4px;
      height: 100%;
      border-right: 2px solid #c0c0c0;
      font-size: 12px;
      font-weight: bold;
      text-align: center;
      display: flex;
      align-items: center;
      word-wrap: break-word;
    }
    .contents-before {
      display: grid;
      grid-template-rows: 128px repeat(7, 64px);
      > div {
        width: 100%;
        display: grid;
        grid-template-columns: 150px 1fr;
        align-items: center;
        border-bottom: 1px solid #c0c0c0;
        .label {
          height: 100%;
          padding: 4px;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          text-align: center;
          white-space: pre-line;
          border-right: 1px solid #c0c0c0;
          background: #ebeef5;
          font-size: 14px;
        }
        // .meeting-selecte-label {
        //   background: white;
        //   position: relative;
        //   .meeting-selecter {
        //     position: absolute;
        //     left: 0;
        //     top: 0;
        //     right: 0;
        //     bottom: 0;
        //     margin: auto;
        //     display: flex;
        //     flex-direction: column;
        //     justify-content: center;
        //     align-items: center;
        //   }
        // }
        .content {
          max-width: calc(100vw - 224px);
          min-width: 940px;
          height: 100%;
        }
        .purpose-content {
          display: grid;
          grid-template-columns: 326px 160px 1fr;
          align-items: center;
          height: 100%;
          .purpose-op {
            border-right: 1px solid #c0c0c0;
            height: 100%;
          }
        }
      }
    }
    .contents-after {
      display: grid;
      grid-template-rows: repeat(3, 64px);
      > div {
        display: grid;
        grid-template-columns: 150px 1fr;
        align-items: center;
        border-bottom: 1px solid #c0c0c0;
        .label {
          height: 100%;
          padding: 4px;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          text-align: center;
          white-space: pre-line;
          border-right: 1px solid #c0c0c0;
          background: #cae2fc;
          font-size: 14px;
        }
        .content {
          max-width: calc(100vw - 224px);
          min-width: 940px;
          height: 100%;
        }
      }
    }
    .op-wrapper {
      max-width: calc(100vw - 220px);
      height: 100%;
      display: grid;
      grid-template-columns: repeat(6, minmax(125px, 1fr));
      .meeting-info {
        display: grid;
        grid-template-rows: 1fr 2fr;
        border-right: 1px solid #c0c0c0;
        .op-label {
          border-bottom: 1px solid #c0c0c0;
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;
          white-space: pre-wrap;
          text-align: center;
          background: #ebeef5;
          font-size: 14px;
        }
      }
    }
  }
  .op {
    position: relative;
    align-self: center;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .date-text {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      white-space: pre-wrap;
      text-align: center;
    }
    .start {
      height: 50%;
      display: grid;
      grid-template-columns: 20px 1fr;
      border-bottom: 1px dashed #c0c0c0;
      > span {
        font-size: 12px;
        font-weight: bold;
        border-right: 1px solid #c0c0c0;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .end {
      height: 50%;
      display: grid;
      grid-template-columns: 20px 1fr;
      > span {
        font-size: 12px;
        font-weight: bold;
        border-right: 1px solid #c0c0c0;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .isChanged {
      background: lightcyan;
    }
  }

  .multiple-text {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    overflow: hidden;
  }
  .tabel-title {
    font-size: 12px;
    font-weight: bold;
  }
  .purpose {
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
  }
  .paragraph {
    font-size: 14px !important;
  }
  .el-icon-delete {
    font-size: 20px;
  }
  .el-icon-delete:hover {
    color: #f56c6c;
  }
  .link-wrapper {
    position: absolute;
    left: 0;
    top: -24px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .el-icon-link {
    font-size: 20px;
  }

  .tips {
    padding: 2px;
    margin-left: 6px;
    line-height: 12px;
    margin-bottom: 2px;
    font-size: 12px;
  }
  .copiedtips {
  }
  .type {
    height: 50%;
    display: grid;
    border-bottom: 1px solid #c0c0c0;
    .date-text {
      margin: 0;
      height: 50%;
    }
  }
  .meeting-name {
    height: 50%;
    display: grid;
    grid-template-columns: 55px 1fr;
  }
  .organizer {
    height: 50%;
    display: grid;
    grid-template-columns: 96px 1fr;
  }
  .writer {
    height: 50%;
    display: flex;
    grid-template-columns: 55px 1fr;
  }
}
</style>
