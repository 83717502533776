<template>
  <div class="week-container">
    <div class="action-plan">
      <div class="title">
        <div class="date" style="justify-content: center">週</div>
        <div class="weekly-action" style="justify-content: center; line-height: 17px">
          【行動計画】
          <br />「行動」と「ゴール」を記載する
        </div>
        <div class="weekly-goal" style="justify-content: center">工夫する点</div>
        <div class="operator" style="justify-content: center">報告</div>
      </div>
      <div class="plan-item" v-for="(item, index) in list" :key="index">
        <div class="date" style="flex-direction: column">
          <div>第{{ index + 1 }}週</div>
          <div style="font-size: 12px">{{ item.weeklyString }}</div>
        </div>
        <div
          class="weekly-action"
          @click.stop="openEditor(item, 'weeklyAction', 'plan')"
          style="align-items: flex-start; padding-top: 4px;"
        >
          <el-popover placement="bottom-start" width="auto" trigger="hover" :offset="-1">
            <div v-html="placesHolder(item.weeklyAction, placesHolderActionHtml, item.isChanged)"></div>
            <template #reference>
              <div>
                <div
                  v-if="!item.weeklyAction && !item.isChanged"
                  class="paragraph"
                  v-html="placesHolderActionHtml"
                ></div>
                <div
                  v-else
                  class="paragraph"
                  v-for="(item, index) in parseTags(item.weeklyAction)"
                  :key="index"
                  v-html="item"
                ></div>
              </div>
            </template>
          </el-popover>
        </div>
        <div
          class="weekly-goal"
          @click.stop="openEditor(item, 'weeklyGoal', 'plan')"
          style="align-items: flex-start; padding-top: 4px;"
        >
          <el-popover placement="bottom-start" width="auto" trigger="hover" :offset="-1">
            <div v-html="placesHolder(item.weeklyGoal, placesHolderGoalHtml, item.isChanged)"></div>
            <template #reference>
              <div>
                <div
                  v-if="!item.weeklyGoal && !item.isChanged"
                  class="paragraph"
                  v-html="placesHolderGoalHtml"
                ></div>
                <div
                  v-else
                  class="paragraph"
                  v-for="(item, index) in parseTags(item.weeklyGoal)"
                  :key="index"
                  v-html="item"
                ></div>
              </div>
            </template>
          </el-popover>
        </div>
        <div class="operator">
          <el-button
            type="primary"
            v-if="isRenderButton(item)"
            size="mini"
            :disabled="isDisabled"
            @click="confirmSubmitPlans(item)"
            >報告</el-button
          >
          <div v-else>{{ formatDate(item.updatedAt) }}</div>
        </div>
      </div>
    </div>
    <div class="review-plan">
      <div class="title">
        <div class="reached-or-not-reached" style="justify-content: center">達成/未達成</div>
        <div class="achievement" style="justify-content: center; line-height: 17px">
          【結果】<br />計画に対する結果を記載する
        </div>
        <div class="comment" style="justify-content: center; line-height: 17px">
          【コメント】<br />達成・未達成に対する要因分析を記載する
        </div>
        <div class="operator" style="border-right: none; justify-content: center">報告</div>
      </div>
      <div class="plan-item" v-for="(item, index) in reviewList" :key="index">
        <div
          class="reached-or-not-reached"
          @click.stop="openEditor(item, 'reachedOrNotReached', 'review')"
        >
          <el-select
            v-model="item.reachedOrNotReached"
            @change="handleSelect(item)"
            :disabled="selectDisabled(item)"
          >
            <el-option :value="0" label="----"></el-option>
            <el-option :value="1" label="達成"></el-option>
            <el-option :value="2" label="未達成"></el-option>
          </el-select>
        </div>
        <div
          class="achievement"
          @click.stop="openEditor(item, 'achievement', 'review')"
          style="align-items: flex-start; padding-top: 4px;"
        >
          <el-popover placement="bottom-start" width="auto" trigger="hover" :offset="-1">
            <div v-html="item.achievement"></div>
            <template #reference>
              <div>
                <div
                  class="paragraph"
                  v-for="(item, index) in parseTags(item.achievement)"
                  :key="index"
                  v-html="item"
                ></div>
              </div>
            </template>
          </el-popover>
        </div>
        <div
          class="comment"
          @click.stop="openEditor(item, 'comment', 'review')"
          style="align-items: flex-start; padding-top: 4px;"
        >
          <el-popover placement="bottom-start" width="auto" trigger="hover" :offset="-1">
            <div v-html="item.comment"></div>
            <template #reference>
              <div>
                <div
                  class="paragraph"
                  v-for="(item, index) in parseTags(item.comment)"
                  :key="index"
                  v-html="item"
                ></div>
              </div>
            </template>
          </el-popover>
        </div>
        <div class="operator" style="border-right: none">
          <el-button
            v-if="isRenderButton(item)"
            type="primary"
            :disabled="isDisabled"
            size="mini"
            @click="confirmSubmitReviews(item)"
            >報告</el-button
          >
          <div v-else style="font-size: 13px">{{ formatDate(item.updatedAt) }}</div>
        </div>
      </div>
    </div>
    <EditorDialog v-model:visible="visible" v-model:content="content" @confirm="onConfirm" />
  </div>
</template>

<script>
import dayjs from 'dayjs'
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)

import { postWeekPlan, postWeekReview } from '@/api/user'
import EditorDialog from '@/components/common/EditorDialog'
import { splitTag } from '@/utils/helper'
import { ElMessageBox } from 'element-plus'

export default {
  props: {
    plans: {
      type: Array,
      default: () => []
    },
    reviews: {
      type: Array,
      default: () => []
    },
    userId: {
      type: Number,
      default: -1
    },
    role: {
      type: String,
      default: ''
    }
  },
  components: {
    EditorDialog
  },
  data() {
    return {
      placesHolderActionHtml:
        '<p>①（行動）&emsp;（ゴール）</p><p>②（行動）&emsp;（ゴール）</p>',
      placesHolderGoalHtml:
        '<p>①</p><p>②</p>',
      visible: false,
      content: '',
      list: [],
      reviewList: [],
      item: null,
      field: '',
      flag: ''
    }
  },
  watch: {
    '$props.plans': {
      handler: function (val) {
        this.list = val
      },
      immediate: true
    },
    '$props.reviews': {
      handler: function (val) {
        this.reviewList = val
      },
      immediate: true
    }
  },
  computed: {
    isDisabled() {
      if (this.userId * 1 !== this.$store.state.user.userInfo.userId) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    placesHolder(item, holder, isChanged) {
      if (isChanged) return item
      else {
        if (item === '') return holder
        return item
      }
    },
    parseTags(str) {
      let tag = splitTag(str)
      return tag.slice(0, 2)
    },
    isRenderButton(item) {
      if (item.isChanged && item.updatedAt) {
        return false
      }
      if (this.userId !== this.$store.state.user.userInfo.userId) {
        return false
      }

      return true
    },
    formatDate(value) {
      return value ? dayjs.tz(value, 'Asia/Tokyo').format('YYYY/MM/DD HH:mm') : ''
    },
    openEditor(item, field, flag) {
      if (this.role !== 'root' && this.role !== 'subRoot') {
        if (this.userId !== this.$store.state.user.userInfo.userId) {
          return false
        }
        if (item.isChanged === true) {
          return false
        }
      }
      if (
        this.role === 'subRoot' &&
        item.isChanged === true &&
        this.userId === this.$store.state.user.userInfo.userId
      )
        return false

      if (this.role === 'subRoot' && this.userId === 3) return false
      this.field = field
      this.item = item
      this.flag = flag
      if (field === 'weeklyAction') {
        this.content = this.placesHolder(item[field], this.placesHolderActionHtml)
      } else if (field === 'weeklyGoal') {
        this.content = this.placesHolder(item[field], this.placesHolderGoalHtml)
      } else {
        this.content = item[field]
      }
      this.visible = true
    },
    selectDisabled(item) {
      if (this.role === 'root') {
        return false
      } else if (this.role === 'subRoot') {
        if (
          (this.userId === this.$store.state.user.userInfo.userId && item.isChanged) ||
          this.userId == 3
        ) {
          return true
        } else {
          return false
        }
      } else {
        if (this.userId !== this.$store.state.user.userInfo.userId || item.isChanged) {
          return true
        } else {
          return false
        }
      }
    },
    handleSelect(item) {
      this.onSubmitReviews(item)
      // if(this.role === 'root') {
      // }
    },
    onConfirm(val) {
      this.item[this.field] = val
      if (this.flag === 'plan') {
        this.onSubmitPlans(this.item)
      } else {
        this.onSubmitReviews(this.item)
      }
      // if(this.role === 'root') {
      // }
    },
    confirmSubmitPlans(item) {
      ElMessageBox.confirm(
        '入力内容でご報告よろしいですか？報告後、該当内容は再編集出来ませんので、ご注意ください。',
        'ご確認',
        {
          confirmButtonText: '確定',
          cancelButtonText: 'キャンセル',
          type: 'primary'
        }
      )
        .then(() => {
          this.onSubmitPlans(item, true)
        })
        .catch((e) => {
          console.log(e)
        })
    },
    onSubmitPlans(item, isAutoLock = false) {
      const data = {
        data: [
          {
            date: dayjs(item.date).format('YYYY-MM-DD'),
            weeklyAction: item.weeklyAction,
            weeklyGoal: item.weeklyGoal
          }
        ],
        userId: this.userId,
        isAutoLock
      }
      postWeekPlan(data).then((res) => {
        this.$emit('updateUser')
      })
    },
    confirmSubmitReviews(item) {
      ElMessageBox.confirm(
        '入力内容でご報告よろしいですか？報告後、該当内容は再編集出来ませんので、ご注意ください。',
        'ご確認',
        {
          confirmButtonText: '確定',
          cancelButtonText: 'キャンセル',
          type: 'primary'
        }
      )
        .then(() => {
          this.onSubmitReviews(item, true)
        })
        .catch((e) => {
          console.log(e)
        })
    },
    onSubmitReviews(item, isAutoLock = false) {
      const data = {
        data: [
          {
            date: dayjs(item.date).format('YYYY-MM-DD'),
            achievement: item.achievement,
            comment: item.comment,
            reachedOrNotReached: item.reachedOrNotReached * 1
          }
        ],
        userId: this.userId * 1,
        isAutoLock
      }
      postWeekReview(data).then((res) => {
        this.$emit('updateUser')
      })
    }
  }
}
</script>
<style lang="scss">
.week-container {
  .el-input--suffix .el-input__inner {
    background-color: #ffffff00;
    border: 0px;
  }
}
</style>
<style lang="scss" scoped>
.week-container {
  display: flex;
  width: 100%;
  font-size: 12px;
  border-top: 1px solid #000;
  .title {
    display: flex;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-weight: bold;
    border-bottom: 1px solid #000;
    .date {
      height: 52px !important;
      flex-wrap: wrap;
      text-align: center;
    }
    .operator {
      height: 52px !important;
      flex-wrap: wrap;
      text-align: center;
    }
    .weekly-goal {
      height: 52px !important;
      flex-wrap: wrap;
      text-align: center;
    }
    .weekly-action {
      height: 52px !important;
      flex-wrap: wrap;
      text-align: center;
    }
    .reached-or-not-reached {
      height: 52px !important;
      flex-wrap: wrap;
      text-align: center;
    }
    .achievement {
      height: 52px !important;
      flex-wrap: wrap;
      text-align: center;
    }
    .comment {
      height: 52px !important;
      flex-wrap: wrap;
      text-align: center;
    }
  }
  .plan-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #000;
    &:nth-child(odd) {
      background: #eff8fe;
    }
  }
  .action-plan,
  .review-plan {
    width: 50%;
    .date {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 45px;
      overflow: hidden;
      border-right: 1px solid #000;
    }
    .operator {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 45px;
      overflow: hidden;
      border-right: 2px solid #000;
    }
    .weekly-goal {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 41px;
      overflow: hidden;
      border-right: 1px solid #000;
    }
    .weekly-action {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 41px;
      border-right: 1px solid #000;
      overflow: hidden;
    }
    .reached-or-not-reached {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 82px;
      height: 45px;
      border-right: 1px solid #000;
      overflow: hidden;
    }
    .achievement {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 41px;
      overflow: hidden;
      border-right: 1px solid #000;
    }
    .comment {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 400px;
      height: 41px;
      overflow: hidden;
      border-right: 1px solid #000;
    }
  }
  .paragraph {
    .text {
      font-size: 14px;
      text-indent: 6px;
      overflow: hidden;
    }
  }
}
</style>
