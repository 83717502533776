<template>
  <div class="daily-container">
    <div class="info">
      <div class="left">
        <div>
          <div>【日々の行動計画】</div>
          <div>行動で何をどのような状態にまで変化させるのイメージ持ってください。</div>
          <!-- <div>どんな「<span style="font-size: 16px">配慮を伴った行動</span>」で、「<span style="font-size: 16px">何を</span>」「<span style="font-size: 16px">どのような状態</span>」に「<span style="font-size: 16px">変える</span>」のかを書いてください。</div> -->
        </div>
      </div>
      <div class="right">
        <!-- 本日の「行動」とその「ゴール」についての振り返り<br />
        （自分のやり方を絶えず修正しょう） -->
        1日を振り返り、計画の実効性等について自己分析してください
      </div>
    </div>
    <div class="daily-header">
      <div class="left">
        <div class="date">日付</div>
        <div class="content">
          <div class="order" style="font-size: 10px">優先順位</div>
          <div class="action">
            <div>
              <!-- <div>（月間・週間目標を達成するための）</div> -->
              <div style="font-weight: bold; font-size: 16px">
                本日の行動とゴールを書いてください
              </div>
            </div>
          </div>
          <div class="dailyGoal">
            <!-- 本日のゴール／達成可否の判断基準も書く <br />
            （達成の可否判断できよう数量化等を記載する） -->
            <div style="font-weight: bold; font-size: 16px">
              行動において、工夫する点を書いてください
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="reachedOrNotReached">ゴールの達成<br />未達成</div>
        <div class="timeCount">所要<br />時間</div>
        <div class="achievement">行動と結果の振り返り</div>
        <div class="degreeOfCompletion">行動の質</div>
        <div class="impression">
          気づき、うまくいった点など<br />（自分で自分にフィードバックして）
        </div>
      </div>
    </div>
    <div class="plan-container">
      <div class="plan-left">
        <div v-for="(item, index) in planList" :key="index" class="plan-item">
          <div class="left" :style="{ background: calcBg(item.background) }">
            <div class="date">{{ `${item.title}\n${item.isHoliday ? '祝日' : ''}` }}</div>
            <div class="content">
              <div v-for="(element, idx) in item.actions" :key="idx" class="row">
                <div class="order" v-if="item.actions.length === 1"></div>
                <div class="order" v-else-if="idx === 4">他</div>
                <div class="order" v-else>{{ idx + 1 }}</div>
                <div
                  class="action"
                  style="position: relative"
                  @click.stop="openEditor(element, 'action', item.isChanged, item, 'plan')"
                >
                  <el-popover placement="bottom-start" width="auto" trigger="hover" :offset="-1">
                    <div
                      v-html="placesHolder(element.action, placesHolderActionHtml, item.isChanged)"
                    ></div>
                    <template #reference>
                      <div>
                        <div
                          v-if="!element.action && !item.isChanged"
                          class="paragraph"
                          v-html="placesHolderActionHtml"
                        ></div>
                        <div
                          v-else
                          v-for="(item, index) in parseTags(element.action)"
                          :key="index"
                          class="paragraph"
                          v-html="item"
                        ></div>
                        <!-- <div v-else class="paragraph" v-html="placesHolderActionHtml"></div> -->
                      </div>
                    </template>
                  </el-popover>
                </div>
                <div
                  class="dailyGoal"
                  :class="{ 'last-daily': idx === 4 || item.actions.length === 1 }"
                  @click.stop="openEditor(element, 'dailyGoal', item.isChanged, item, 'plan')"
                >
                  <div class="pop-wrap">
                    <el-popover placement="bottom-start" width="auto" trigger="hover" :offset="-1">
                      <div
                        v-html="
                          placesHolder(element.dailyGoal, placesHolderDailyGoalHtml, item.isChanged)
                        "
                      ></div>
                      <template #reference>
                        <div>
                          <div
                            v-if="!element.dailyGoal && !item.isChanged"
                            class="paragraph"
                            v-html="placesHolderDailyGoalHtml"
                          ></div>
                          <div
                            v-else
                            v-for="(item, index) in parseTags(element.dailyGoal)"
                            :key="index"
                            class="paragraph"
                            v-html="item"
                          ></div>
                        </div>
                      </template>
                    </el-popover>
                  </div>
                  <div class="daily-post" v-if="idx === 4 || item.actions.length === 1">
                    <div
                      v-if="item.updatedAt"
                      style="
                        border: 1px dashed darkslategrey !important;
                        line-height: 12px;
                        padding: 2px;
                        margin-right: 6px;
                        background-color: lightcyan;
                      "
                    >
                      {{ formatDate(item.updatedAt) }}
                    </div>
                    <div v-else>
                      <el-button
                        v-if="isRenderButton"
                        class="submit-button"
                        style="height: 20px!important;
                                  min-height: auto!important;
                                  line-height: 20px!important;
                                  display: flex!important;
                                  align-items: center!important;
                                  float: right;
                                  margin-right: 6px;
                                  padding: 6px 10px!important;
                                  font-size: 10px;
                                  border-radius: 2px;
                                  min-height: auto;!important;"
                        type="primary"
                        :disabled="item.isChanged"
                        @click.stop="confirmSubmitPlans(item)"
                        >報告
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="plan-right">
        <div
          :key="index"
          v-for="(item, index) in reviewList"
          class="plan-item"
          :style="{ background: calcBg(item.background) }"
        >
          <div v-if="item.isDisable" class="disable-box">
            <div class="reachedOrNotReached"></div>
            <div class="timeCount"></div>
            <div class="achievement"></div>
            <div class="degreeOfCompletion"></div>
            <div class="impression"></div>
          </div>
          <div v-else class="right">
            <div v-for="(element, idx) in item.actions" :key="idx" class="row">
              <div
                class="reachedOrNotReached"
                @click.stop="
                  openEditor(element, 'reachedOrNotReached', item.isChanged, item, 'review')
                "
              >
                <el-select
                  v-model="element.reachedOrNotReached"
                  placeholder=""
                  :disabled="selectDisabled(item)"
                  @change="handleChange(item, $event)"
                >
                  <el-option :value="0" label="---"></el-option>
                  <el-option :value="1" label="達成"></el-option>
                  <el-option :value="2" label="未達成"></el-option>
                  <el-option :value="3" label="完了"></el-option>
                  <el-option :value="4" label="未完了"></el-option>
                  <el-option :value="5" label="継続"></el-option>
                  <el-option :value="6" label="先送り"></el-option>
                </el-select>
              </div>
              <div class="timeCount">
                <el-select
                  v-model="element.timeCount"
                  placeholder=""
                  :disabled="selectDisabled(item)"
                  @change="handleChange(item, $event)"
                >
                  <el-option label="---" :value="0"></el-option>
                  <el-option label="10分" :value="10"></el-option>
                  <el-option label="20分" :value="20"></el-option>
                  <el-option label="30分" :value="30"></el-option>
                  <el-option label="40分" :value="40"></el-option>
                  <el-option label="50分" :value="50"></el-option>
                  <el-option label="1時間" :value="60"></el-option>
                  <el-option label="1時間30分" :value="90"></el-option>
                  <el-option label="2時間" :value="120"></el-option>
                  <el-option label="2時間30分" :value="150"></el-option>
                  <el-option label="3時間" :value="180"></el-option>
                  <el-option label="3時間30分" :value="210"></el-option>
                  <el-option label="4時間" :value="240"></el-option>
                  <el-option label="4時間30分" :value="270"></el-option>
                  <el-option label="5時間" :value="300"></el-option>
                  <el-option label="5時間30分" :value="330"></el-option>
                  <el-option label="6時間" :value="360"></el-option>
                  <el-option label="6時間30分" :value="390"></el-option>
                  <el-option label="7時間" :value="420"></el-option>
                  <el-option label="7時間30分" :value="450"></el-option>
                  <el-option label="8時間" :value="480"></el-option>
                  <el-option label="8時間30分" :value="510"></el-option>
                  <el-option label="9時間" :value="540"></el-option>
                  <el-option label="9時間30分" :value="570"></el-option>
                  <el-option label="10時間" :value="600"></el-option>
                  <el-option label="10時間30分" :value="630"></el-option>
                  <el-option label="11時間" :value="660"></el-option>
                  <el-option label="11時間30分" :value="690"></el-option>
                  <el-option label="12時間" :value="720"></el-option>
                </el-select>
              </div>
              <div
                class="achievement"
                @click.stop="openEditor(element, 'achievement', item.isChanged, item, 'review')"
              >
                <el-popover placement="bottom-start" width="auto" trigger="hover" :offset="-1">
                  <div v-html="element.achievement"></div>
                  <template #reference>
                    <div>
                      <div
                        v-for="(item, index) in parseTags(element.achievement)"
                        :key="index"
                        class="paragraph"
                        v-html="item"
                      ></div>
                    </div>
                  </template>
                </el-popover>
              </div>
              <div
                class="degreeOfCompletion"
                @click.stop="
                  openEditor(element, 'degreeOfCompletion', item.isChanged, item, 'review')
                "
              >
                <el-select
                  v-model="element.degreeOfCompletion"
                  placeholder=""
                  :disabled="selectDisabled(item)"
                  @change="handleChange(item, $event)"
                >
                  <el-option label="---" :value="0"></el-option>
                  <el-option label="高" :value="1"></el-option>
                  <el-option label="中" :value="2"></el-option>
                  <el-option label="低" :value="3"></el-option>
                </el-select>
              </div>
              <div
                class="impression"
                :class="{ 'last-daily': idx === 4 || item.actions.length === 1 }"
                @click.stop="openEditor(element, 'impression', item.isChanged, item, 'review')"
              >
                <div class="pop-wrap">
                  <el-popover placement="bottom-start" width="auto" trigger="hover" :offset="-1">
                    <div v-html="element.impression"></div>
                    <template #reference>
                      <div>
                        <div
                          v-for="(item, index) in parseTags(element.impression)"
                          :key="index"
                          class="paragraph"
                          v-html="item"
                        ></div>
                      </div>
                    </template>
                  </el-popover>
                </div>

                <div class="daily-post" v-if="idx === 4 || item.actions.length === 1">
                  <div
                    v-if="item.updatedAt"
                    style="
                      border: 1px dashed darkslategrey;
                      line-height: 12px;
                      padding: 2px;
                      margin-right: 6px;
                      background-color: lightcyan;
                    "
                  >
                    {{ formatDate(item.updatedAt) }}
                  </div>
                  <div v-else>
                    <el-button
                      v-if="isRenderButton"
                      :disabled="item.isChanged"
                      class="submit-button"
                      style="height: 20px!important;
                        min-height: auto!important;
                        line-height: 20px!important;
                        display: flex!important;
                        align-items: center!important;
                        padding: 6px 10px!important;
                        font-size: 10px;
                        float: right;
                        margin-right: 6px;
                        border-radius: 2px;
                        min-height: auto;!important;"
                      type="primary"
                      @click.stop="confirmSubmitReviews(item)"
                      >報告
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <EditorDialog v-model:content="content" v-model:visible="visible" @confirm="onConfirm" />
  </div>
</template>

<script>
import { postDailyPlan, postDailyReviewPlan } from '@/api/user'
import EditorDialog from '@/components/common/EditorDialog'

import dayjs from 'dayjs'
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)
import { splitTag } from '@/utils/helper'
import { ElMessageBox } from 'element-plus'

export default {
  name: 'DailyPlan',
  props: {
    plans: {
      type: Array,
      default: () => []
    },
    reviews: {
      type: Array,
      default: () => []
    },
    userId: {
      type: Number,
      default: -1
    },
    role: {
      type: String,
      default: ''
    }
  },
  components: {
    EditorDialog
  },
  data() {
    return {
      placesHolderActionHtml:
        '<p>（行動）&emsp;（ゴール）</p>',
      placesHolderDailyGoalHtml: '<p>（工夫する点）</p>',
      planList: [],
      reviewList: [],
      finalList: [],
      visible: false,
      content: '',
      item: '',
      field: '',
      row: '',
      htmlTitle1:
        '【日々の行動計画】<br />どんな「<b>配慮を伴った行動</b>」で、「何を」「どのような状態」に「変える」のかを書いてください。'
    }
  },
  watch: {
    '$props.plans': {
      handler: function (data) {
        const deepData = JSON.parse(JSON.stringify(data))
        this.planList = deepData
      },
      immediate: true
    },
    '$props.reviews': {
      handler: function (data) {
        const deepData = JSON.parse(JSON.stringify(data))
        this.reviewList = deepData
      },
      immediate: true
    }
  },
  computed: {
    isRenderButton() {
      if (this.userId * 1 !== this.$store.state.user.userInfo.userId * 1) {
        return false
      }
      return true
    }
  },
  methods: {
    placesHolder(item, holder, isChanged) {
      if (isChanged) return item
      else {
        if (item === '') return holder
        return item
      }
    },
    parseTags(str) {
      let tag = splitTag(str?.trim())
      tag = tag.slice(0, 1)
      return tag
    },
    calcBg(color) {
      if (color === 'green') {
        return '#F0F8FF'
      } else if (color === 'gray') {
        return '#e0e0e0'
      } else if (color === 'yellow') {
        return '#fdfd84'
      } else {
        return '#fff'
      }
    },
    openEditor(item, field, isChanged, row, flag) {
      if (this.role !== 'root' && this.role !== 'subRoot') {
        if (isChanged || this.userId !== this.$store.state.user.userInfo.userId) return false
      }
      if (this.role === 'subRoot') {
        if (
          this.userId === 3 ||
          (this.userId === this.$store.state.user.userInfo.userId && isChanged)
        )
          return false
      }
      this.flag = flag
      this.field = field
      this.item = item
      if (field === 'action') {
        this.content = this.placesHolder(item[field], this.placesHolderActionHtml)
      } else if (field === 'dailyGoal') {
        this.content = this.placesHolder(item[field], this.placesHolderDailyGoalHtml)
      } else {
        this.content = item[field]
      }
      this.visible = true
      this.row = row
    },
    handleChange(item) {
      this.submitReview(item)
    },
    onConfirm(val) {
      this.item[this.field] = val
      if (this.flag === 'plan') {
        this.submitPlan(this.row)
      } else {
        this.submitReview(this.row)
      }
      // if(this.role === 'root') {
      // }
    },
    confirmSubmitPlans(item) {
      ElMessageBox.confirm(
        '入力内容でご報告よろしいですか？報告後、該当内容は再編集出来ませんので、ご注意ください。',
        'ご確認',
        {
          confirmButtonText: '確定',
          cancelButtonText: 'キャンセル',
          type: 'primary'
        }
      )
        .then(() => {
          this.submitPlan(item, true)
        })
        .catch((e) => {
          console.log(e)
        })
    },
    submitPlan(item, isAutoLock = false) {
      const actions = item.actions.map((element) => {
        return {
          id: element.id,
          action: element.action,
          dailyGoal: element.dailyGoal
        }
      })
      const data = {
        ...item,
        actions,
        userId: this.userId,
        date: dayjs(item.date).format('YYYY-MM-DD'),
        isAutoLock
      }
      postDailyPlan(data).then((res) => {
        this.$emit('updateUser')
      })
    },
    selectDisabled(item) {
      if (this.userId !== this.$store.state.user.userInfo.userId || item.isChanged) {
        return true
      } else {
        return false
      }
    },
    confirmSubmitReviews(item) {
      ElMessageBox.confirm(
        '入力内容でご報告よろしいですか？報告後、該当内容は再編集出来ませんので、ご注意ください。',
        'ご確認',
        {
          confirmButtonText: '確定',
          cancelButtonText: 'キャンセル',
          type: 'primary'
        }
      )
        .then(() => {
          this.submitReview(item, true)
        })
        .catch((e) => {
          console.log(e)
        })
    },
    submitReview(item, isAutoLock = false) {
      const actions = item.actions.map((element) => {
        return {
          ...element,
          reachedOrNotReached: element.reachedOrNotReached,
          achievement: element.achievement,
          impression: element.impression
        }
      })
      const data = {
        ...item,
        actions,
        userId: this.userId * 1,
        date: dayjs(item.date).format('YYYY-MM-DD'),
        isAutoLock
      }
      postDailyReviewPlan(data).then((res) => {
        this.$emit('updateUser')
      })
    },
    formatDate(str) {
      return str ? dayjs.tz(str, 'Asia/Tokyo').format('MM/DD HH:mm') : ''
    }
  }
}
</script>

<style lang="scss">
.daily-container {
  .paragraph {
    height: 26px !important;
    line-height: 26px !important;
    font-size: 12px !important;
  }
  .el-input--suffix .el-input__inner {
    background-color: #ffffff00;
    border: 0px;
  }
  .el-select .el-input.is-disabled .el-input__inner {
    background-color: #ffffff00;
    color: #2c3e50;
  }
}
</style>
<style lang="scss" scoped>
.daily-container {
  .info {
    display: flex;
    height: 60px;
    font-size: 14px;
    font-weight: bold;
    white-space: normal;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;

    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding: 0 6px;
      width: 50%;
      height: 100%;
      background: #00d659;
      border-right: 2px solid #000;
      font-size: 12px;
    }

    .right {
      box-sizing: border-box;
      padding: 0 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: 100%;
      background: #fecf28;
      font-size: 12px;
    }
  }

  .daily-header {
    display: flex;
    height: 50px;
    font-size: 12px;
    border-bottom: 2px solid #000;

    .left {
      width: 50%;
      display: flex;
      background: #00d659;
      overflow: hidden;

      .date {
        width: 48px;
        border-right: 1px solid #000;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
      }

      .content {
        display: flex;
        flex: 1;
        overflow: hidden;

        .order {
          min-width: 26px;
          max-width: 26px;
          font-size: 12px;
          border-right: 1px solid #000;
          display: flex;
          align-items: center;
        }

        .action {
          display: flex;
          align-items: center;
          width: 50%;
          border-right: 1px solid #000;
          margin: 0 2px;
          display: flex;
          justify-content: center;
        }

        .dailyGoal {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50%;
          border-right: 2px solid #000;
        }
      }
    }

    .right {
      width: 50%;
      display: flex;
      overflow: hidden;
      background: #fecf28;

      .reachedOrNotReached {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 80px;
        max-width: 80px;
        border-right: 1px solid #000;
      }

      .timeCount {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 100px;
        max-width: 100px;
        border-right: 1px solid #000;
      }

      .achievement {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        border-right: 1px solid #000;
      }

      .degreeOfCompletion {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 56px;
        max-width: 56px;
        border-right: 1px solid #000;
      }

      .impression {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
      }
    }
  }

  .plan-item {
    width: 100%;
    border-bottom: 2px solid #000;

    .left {
      display: flex;
      align-items: center;

      .date {
        width: 48px;
        font-size: 12px;
      }

      .disable-box {
        flex: 1;
        display: flex;
        border-left: 1px solid #000;

        .order {
          min-width: 26px;
          max-width: 26px;
          font-size: 12px;
          border-right: 1px solid #000;
        }

        .action {
          width: 50%;
          height: 26px;
          overflow: hidden;
          border-right: 1px solid #000;
        }

        .dailyGoal {
          width: 50%;
          height: 26px;
          overflow: hidden;
          border-right: 1px solid #000;
        }
      }

      .content {
        flex: 1;
        border-left: 1px solid #000;
        overflow: hidden;

        .row {
          display: flex;
          width: 100%;
          line-height: 26px;

          &:last-child {
            .action,
            .dailyGoal,
            .order {
              border-bottom: none;
            }
          }
        }

        .order {
          min-width: 26px;
          max-width: 26px;
          font-size: 12px;
          border-bottom: 1px dotted #000;
          border-right: 1px solid #000;
        }

        .action {
          width: 50%;
          height: 26px;
          overflow: hidden;
          border-bottom: 1px dotted #000;
          border-right: 1px solid #000;
        }

        .dailyGoal {
          width: 50%;
          height: 26px;
          overflow: hidden;
          border-right: 2px solid #000;
          border-bottom: 1px dotted #000;
        }
      }
    }

    .right {
      .row {
        width: 100%;
        display: flex;
        line-height: 26px;

        &:last-child {
          .reachedOrNotReached,
          .timeCount,
          .achievement,
          .degreeOfCompletion,
          .impression {
            border-bottom: none;
          }
        }
      }

      .reachedOrNotReached {
        min-width: 80px;
        max-width: 80px;
        height: 26px;
        overflow: hidden;
        border-right: 1px solid #000;
        border-bottom: 1px dotted #000;
      }

      .timeCount {
        min-width: 100px;
        max-width: 100px;
        height: 26px;
        overflow: hidden;
        border-right: 1px solid #000;
        border-bottom: 1px dotted #000;
      }

      .achievement {
        flex: 1;
        height: 26px;
        overflow: hidden;
        border-right: 1px solid #000;
        border-bottom: 1px dotted #000;
      }

      .degreeOfCompletion {
        min-width: 56px;
        max-width: 56px;
        height: 26px;
        overflow: hidden;
        border-right: 1px solid #000;
        border-bottom: 1px dotted #000;
      }

      .impression {
        flex: 1;
        height: 26px;
        overflow: hidden;
        border-bottom: 1px dotted #000;
      }
    }
  }

  .plan-container {
    width: 100%;
    display: flex;
  }

  .plan-left,
  .plan-right {
    width: 50%;
  }

  .plan-right {
    .disable-box {
      flex: 1;
      display: flex;

      .reachedOrNotReached {
        min-width: 80px;
        max-width: 80px;
        height: 26px;
        overflow: hidden;
        border-right: 1px solid #000;
        border-bottom: none;
      }

      .timeCount {
        min-width: 100px;
        max-width: 100px;
        height: 26px;
        overflow: hidden;
        border-right: 1px solid #000;
        border-bottom: none;
      }

      .achievement {
        flex: 1;
        height: 26px;
        overflow: hidden;
        border-right: 1px solid #000;
        border-bottom: none;
      }

      .degreeOfCompletion {
        min-width: 56px;
        max-width: 56px;
        height: 26px;
        overflow: hidden;
        border-right: 1px solid #000;
        border-bottom: none;
      }

      .impression {
        flex: 1;
        height: 26px;
        overflow: hidden;
        border-bottom: none;
      }
    }
  }

  .paragraph {
    text-align: left;
    padding: 0 6px;
    overflow: hidden;
  }

  .btn-part {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 60px;
    display: flex;
    align-items: center;

    .submit-button {
      height: 20px !important;
      min-height: auto !important;
      line-height: 20px !important;
      display: flex !important;
      align-items: center !important;
      margin-top: 2px !important;
    }

    .updated-at {
      font-size: 14px;
      overflow: hidden;
    }
  }
  .last-daily {
    width: 100%;
    overflow: hidden;
    display: flex;
    .daily-post {
      min-width: fit-content;
      height: 100%;
      overflow: hidden;
      font-size: 12px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
    }
    .pop-wrap {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
}
</style>
