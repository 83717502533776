import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
// @ts-ignore
import { getToken } from '@/utils/auth'

import Home from '@/views/home.vue'
import Meeting from '@/views/meeting.vue'
import Marketing from '@/views/marketing.vue'
import Login from '@/views/login.vue'
import changePassword from '@/views/changePassword.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/meeting',
    name: 'Meeting',
    component: Meeting
  },
  {
    path: '/marketing',
    name: 'Marketing',
    component: Marketing
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    component: changePassword
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  document.title = 'O2Oナラティブ'
  const hasToken = getToken()
  if (hasToken) {
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      next()
    }
  } else {
    if (to.path === '/login') {
      next()
    } else {
      next({ path: '/login' })
    }
  }
})
export default router
