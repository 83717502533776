<template>
  <div class="set-title" @click.stop="openEditor" :style="wrapperStyle">
    <div class="title">{{ title }}</div>
    <div class="content">
      <el-popover placement="bottom-start" width="auto" trigger="hover" :offset="-1">
        <div v-html="content"></div>
        <template #reference>
          <div>
            <div
              class="paragraph"
              style="height: auto"
              v-for="(item, index) in tags"
              :key="index"
              v-html="item"
            ></div>
          </div>
        </template>
      </el-popover>
    </div>
    <EditorDialog v-model:visible="visible" v-model:content="content" @confirm="handleConfirm" />
  </div>
</template>

<script>
import { computed, defineComponent, ref, toRef, watch } from 'vue'
import EditorDialog from '@/components/common/EditorDialog'
import { splitTag } from '@/utils/helper'
export default defineComponent({
  name: 'bulletinTitle',
  props: {
    date: {
      require: true,
      type: String
    },
    title: {
      type: String,
      default: ''
    },
    titles: {
      type: String,
      default: ''
    },
    role: {
      type: String,
      default: ''
    },
    wrapperStyle: {
      type: Object,
      default: {}
    },
    maxTextLine: {
      type: Number,
      default: 3
    }
  },
  components: { EditorDialog },
  setup: (props, { emit }) => {
    const title = toRef(props, 'titles')

    const visible = ref(false)
    const content = ref('')

    const openEditor = () => {
      if (props.role !== 'root' && props.role !== 'subRoot') {
        return false
      }
      visible.value = true
    }
    const handleConfirm = (value) => {
      content.value = value
      emit('updateTeam', value)
    }
    const tags = computed(() => {
      let tag = splitTag(content.value)
      return tag.slice(0, props.maxTextLine)
    })

    watch(
      title,
      (data) => {
        content.value = data
      },
      {
        immediate: true
      }
    )
    return {
      visible,
      content,
      tags,
      openEditor,
      handleConfirm
    }
  }
})
</script>

<style lang="scss" scoped>
.set-title {
  border: 2px solid #000;
  flex-direction: column;
  min-height: 30px;
  padding-bottom: 6px;
  cursor: pointer;
  font-size: 12px;
  .title {
    height: 35px;
    font-weight: bold;
  }
  .content {
    height: 100%;
    max-width: calc(50vw - 40px);
  }
}
</style>
