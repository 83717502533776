<template>
  <div class="set-title" @click.stop="openEditor">
    <div class="content">
      <el-popover placement="bottom-start" width="auto" trigger="hover" :offset="-1">
        <div v-html="content"></div>
        <template #reference>
          <div style='height: 60px'>
            <div
              class="paragraph"
              style="height: auto"
              v-for="(item, index) in tags"
              :key="index"
              v-html="item"
            ></div>
          </div>
        </template>
      </el-popover>
    </div>
    <EditorDialog v-model:visible="visible" v-model:content="content" @confirm="handleConfirm" />
  </div>
</template>

<script>
import { computed, defineComponent, ref, toRef, watch } from 'vue'
import EditorDialog from '@/components/common/EditorDialog'
import { splitTag } from '@/utils/helper'
import { postMeetingTitle } from '@/api/meeting'
export default defineComponent({
  name: 'MeetingTitle',
  props: {
    date: {
      require: true,
      type: String
    },
    titles: {
      type: String,
      default: ''
    },
    role: {
      type: String,
      default: ''
    }
  },
  components: { EditorDialog },
  setup: (props, { emit }) => {
    const title = toRef(props, 'titles')

    const visible = ref(false)
    const content = ref('')

    const openEditor = () => {
      if (props.role !== 'root' && props.role !== 'subRoot') {
        return false
      }
      visible.value = true
    }
    const handleConfirm = (value) => {
      content.value = value
      postMeetingTitle({
        titles: content.value
      }).then(() => {
        emit('updateMeeting')
      })
    }
    const tags = computed(() => {
      return splitTag(content.value)
    })

    watch(
      title,
      (data) => {
        content.value = data
      },
      {
        immediate: true
      }
    )
    return {
      visible,
      content,
      tags,
      openEditor,
      handleConfirm
    }
  }
})
</script>

<style lang="scss" scoped>
.set-title {
  padding: 10px 0 30px;
  height: 52px;
  cursor: pointer;
  .content {
    height: 100%;
    overflow: hidden;
  }
}
</style>
