export function parseTag(s, delcomma = false) {
  const str = s.replace(/<br\/>|<br>/g, '</p><p>')

  const tags = str.split('</p>').filter((tag) => tag !== '')
  return tags.map((tag) => {
    tag = tag + '</p>'
    const regex1 = new RegExp('(i?)(<p)(?!(.*?style=[\'"](.*)[\'"])[^>]+>)', 'gmi')
    tag = tag.replace(regex1, '$2 style=""$3')
    const regex2 = new RegExp('(i?)(<p.*?style=[\'"])([^>]+>)', 'gmi')
    tag = tag.replace(regex2, '$2overflow:hidden;white-space:nowrap;$3')
    if (delcomma) {
      if (tag[0] === ',') {
        tag = tag.slice(1)
      }
    }
    return tag
  })
}

export function splitTag(s) {
  if (typeof s === 'string') {
    const str = s.replace(/<br\/>|<br>/g, '</p><p>')
    const tags = str.split('</p>').filter((tag) => tag !== '')
    return tags.map((tag) => {
      tag = tag + '</p>'
      const regex1 = new RegExp('(i?)(<p)(?!(.*?style=[\'"](.*)[\'"])[^>]+>)', 'gmi')
      tag = tag.replace(regex1, '$2 style=""$3')
      const regex2 = new RegExp('(i?)(<p.*?style=[\'"])([^>]+>)', 'gmi')
      tag = tag.replace(regex2, '$2overflow:hidden;white-space:nowrap;$3')

      return tag
    })
  } else {
    return []
  }
}
