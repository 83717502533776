<template>
  <div
    class="table-cell"
    @click.stop="openEditor"
    :style="{
      justifyContent: top ? 'flex-start' : 'center',
      padding: top ? '4px' : '0',
      boxSizing: 'border-box'
    }"
  >
    <div class="table-content">
      <el-popover placement="bottom-start" width="auto" trigger="hover" :offset="-1">
        <div v-html="content"></div>
        <template #reference>
          <div>
            <div
              :class="['paragraph', center && 'paragraph-center']"
              v-for="(item, index) in tags"
              :key="index"
              v-html="item"
              :style="{ height: rowHeight }"
            ></div>
          </div>
        </template>
      </el-popover>
    </div>
    <EditorDialog v-model:visible="visible" v-model:content="content" @confirm="handleConfirm" />
  </div>
</template>

<script>
import { splitTag } from '@/utils/helper'
import { computed, defineComponent, ref, toRefs, watch } from 'vue'
import EditorDialog from '@/components/common/EditorDialog'

export default defineComponent({
  name: 'table-cell',
  props: {
    goal: {
      type: String,
      default: ''
    },
    maxLine: {
      type: Number,
      default: 3
    },
    rowHeight: {
      type: String,
      default: '20px'
    },
    freeze: {
      type: Boolean,
      default: false
    },
    role: {
      type: String,
      default: ''
    },
    teamPossesion: {
      type: Boolean,
      default: false
    },
    team: {
      type: Number,
      default: -1
    },
    hasPermission: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    },
    top: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update'],
  components: { EditorDialog },
  setup: (props, { emit }) => {
    const content = ref('')
    const visible = ref(false)
    const handleConfirm = (content) => {
      emit('update', content)
      // root以外も自動保存できるように
      emit('boss', props.team)
      // if(props.role === 'root') {
      // }
    }
    const openEditor = () => {
      if (editable.value) {
        visible.value = true
      } else {
        return false
      }
    }
    const tags = computed(() => {
      let tags = splitTag(content.value?.trim())
      tags = tags.slice(0, props.maxLine)

      return tags
    })

    const editable = computed(() => {
      if (props.hasPermission && !props.freeze) return true
      if (props.role === 'root' || props.role === 'subRoot') {
        return true
      } else if (props.role === 'leader') {
        if (props.freeze) {
          return false
        } else if (!props.teamPossesion) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    })
    watch(
      () => props.goal,
      (newValue) => {
        content.value = newValue
      },
      {
        immediate: true
      }
    )

    return {
      content,
      visible,
      tags,
      editable,
      openEditor,
      handleConfirm
    }
  }
})
</script>

<style lang="scss" scoped>
.table-cell {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .paragraph {
    display: flex;
    padding: 0 6px;
    font-size: 12px;
    overflow: hidden;
  }
  .paragraph-center {
    justify-content: center;
  }
}
</style>
