<template>
  <el-dialog
    :title="title"
    v-model="shown"
    :show-close="false"
    :close-on-click-modal="true"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    :lock-scroll="true"
    width="80%"
  >
    <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="shown = false"> キャンセル</el-button>
        <el-button type="primary" @click="confirm">確定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline'
import Font from '@ckeditor/ckeditor5-font/src/font'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import { watch, toRefs, reactive, computed, defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'EditorDialog',
  props: {
    title: String,
    visible: Boolean,
    content: String | Array
  },
  emits: ['update:content', 'update:visible', 'confirm'],
  setup: (props, { emit }) => {
    const store = useStore()
    const userInfo = computed(() => store.state.user.userInfo)
    const { content } = toRefs(props)
    const dataMap = reactive({
      value: '',
      editor: ClassicEditor,
      editorData: '',

      confirm() {
        if (content.value !== dataMap.editorData) {
          emit('confirm', dataMap.editorData)
          dataMap.editorData = content.value
        }

        emit('update:visible', false)
      }
    })
    const shown = computed({
      get() {
        return props.visible
      },
      set(newValue) {
        emit('update:visible', newValue)
      }
    })

    let _scrollTop = 0

    watch(shown, (value) => {
      if (value) {
        _scrollTop = document.scrollingElement.scrollTop
        document.body.style.position = 'fixed'
        document.body.style.top = -_scrollTop + 'px'
      } else {
        document.body.style.position = ''
        document.body.style.top = ''
        document.scrollingElement.scrollTop = _scrollTop
      }
    })

    watch(
      content,
      (newValue) => {
        dataMap.editorData = newValue
      },
      { immediate: true }
    )
    const editorConfig = computed(() => {
      let toolbar
      if (userInfo.value.role === 'root' || userInfo.value.role === 'subRoot') {
        toolbar = {
          items: ['bold', 'italic', 'underline', 'fontColor', 'FontBackgroundColor']
        }
      }

      if (userInfo.value.role === 'leader') {
        toolbar = {
          items: ['bold', 'italic', 'underline', 'FontBackgroundColor']
        }
      }
      if (userInfo.value.role === 'user') {
        toolbar = {
          items: ['FontBackgroundColor']
        }
      }
      return {
        plugins: [
          EssentialsPlugin,
          BoldPlugin,
          ItalicPlugin,
          LinkPlugin,
          UnderlinePlugin,
          Font,
          ParagraphPlugin
        ],
        toolbar
      }
    })

    return {
      ...toRefs(dataMap),
      editorConfig,
      shown,
      userInfo
    }
  }
})
</script>

<style scoped>
a {
  color: #42b983;
}
</style>
