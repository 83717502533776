import request from '@/utils/axios'

// 获取半期计划标题keyword
export function fetchTeamGoalTitle(date) {
  return request({
    url: `/team/get_team_half_year_goal_title?date=${date}`,
    method: 'get'
  })
}

// 提交半期计划标题keyword
export function postTeamGoalTitle(data) {
  return request({
    url: `/team/post_team_half_year_goal_title`,
    method: 'post',
    data
  })
}

// 提交公告栏
export function postBulletinTitle(data) {
  return request({
    url: '/team/post_bulletin_board',
    method: 'post',
    data
  })
}

// 获取目标振返标题
export function fetchTeamMonthTitle(date) {
  return request({
    url: `/team/get_team_monthly_goal_title?date=${date}`,
    method: 'get'
  })
}

// 提交目标振返标题
export function postTeamMonthTitle(data) {
  return request({
    url: '/team/post_team_monthly_goal_title',
    method: 'post',
    data
  })
}

// 获取半期、月计划列表
export function fetchTeamGoal(date) {
  return request({
    url: `/team/get_team_goal?date=${date}`,
    method: 'get'
  })
}

// 获取半期重设定列表
export function fetchResetTeamGoal(date) {
  return request({
    url: `/team/get_reset_team_goal?date=${date}`,
    method: 'get'
  })
}
// 修改半期计划
export function updateHalfYearGoal(data) {
  return request({
    url: '/team/post_team_half_year_goal',
    method: 'post',
    data
  })
}
// 修改reset半期计划
export function updateResetHalfYearGoal(data) {
  return request({
    url: '/team/post_team_half_year_goal',
    method: 'post',
    data
  })
}

// 修改月度计划
export function updateMonthGoal(data) {
  return request({
    url: '/team/post_team_monthly_goal',
    method: 'post',
    data
  })
}
