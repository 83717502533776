<template>
  <div class="plan-container">
    <div class="team-group">
      <div class="order">
        <div class="title">{{ dataTitle }}</div>
        <div class="content">
          <div v-for="n in 3" :key="n" class="order-item">{{ n }}</div>
          <div v-if="isRenderButton" class="order-item-op">報告</div>
        </div>
      </div>
      <div v-for="(item, index) in teamData" :key="index" class="team-item">
        <div class="team-title">
          <div class="name">{{ item.teamName }}</div>
          <div v-if="item.updatedAt" class="date">
            （見直し日：{{ formatDate(item.updatedAt) }})
          </div>
        </div>
        <div class="team-content">
          <div v-for="(goal, idx) in item.goals" :key="idx" class="goal">
            <table-cell
              top
              :freeze="item.isChanged"
              :goal="goal"
              :role="role"
              :team="item.teamId"
              :teamPossesion="teamId.includes(item.teamId)"
              @update="(value) => (item.goals[idx] = value)"
              @boss="bossSubmit"
            />
          </div>
        </div>
        <div v-if="isRenderButton" class="submit-btn">
          <el-button
            v-if="teamId.includes(item.teamId) || role === 'root' || role === 'subRoot'"
            style="width: 120px"
            size="mini"
            :disabled="item.isChanged"
            type="primary"
            @click="confirmSubmit(item)"
            >報告</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)
import tableCell from '@/components/common/table-cell'
import { computed, defineComponent, ref, toRef, watch } from 'vue'
import { updateResetHalfYearGoal } from '@/api/team'
import { ElMessageBox } from 'element-plus'

export default defineComponent({
  name: 'YearPlan',
  props: {
    list: {
      require: true,
      type: Array
    },
    dataTitle: {
      type: String,
      default: ''
    },
    role: {
      type: String,
      default: ''
    },
    teamId: {
      type: Array,
      default: () => []
    },
    date: {
      type: String,
      default: ''
    }
  },
  components: {
    tableCell
  },
  setup: (props, { emit }) => {
    const listCopy = toRef(props, 'list')
    const teamData = ref([])
    const formatDate = (value) => (value ? dayjs.tz(value, 'Asia/Tokyo').format('YYYY/MM/DD') : '')
    const confirmSubmit = (item) => {
      ElMessageBox.confirm(
        '入力内容でご報告よろしいですか？報告後、該当内容は再編集出来ませんので、ご注意ください。',
        'ご確認',
        {
          confirmButtonText: '確定',
          cancelButtonText: 'キャンセル',
          type: 'primary'
        }
      )
        .then(() => {
          onSubmit(item, true)
        })
        .catch((e) => {
          console.log(e)
        })
    }
    const onSubmit = (item, isAutoLock = false) => {
      const goal_1 = item.goals[0]
      const goal_2 = item.goals[1]
      const goal_3 = item.goals[2]
      const data = [
        {
          ...item,
          goal_1,
          goal_2,
          goal_3,
          date: props.date + '-01',
          isAutoLock
        }
      ]
      updateResetHalfYearGoal(data).then((res) => {
        emit('updateTeam')
      })
    }
    const bossSubmit = (id) => {
      const item = teamData.value.find((item) => item.teamId === id)
      onSubmit(item)
    }
    const isRenderButton = computed(() => {
      if (props.role === 'leader' || props.role === 'root' || props.role === 'subRoot') {
        return !teamData.value.every((item) => item.isChanged)
      } else {
        return false
      }
    })
    watch(
      listCopy,
      (data) => {
        teamData.value = data
      },
      {
        immediate: true
      }
    )
    return {
      teamData,
      formatDate,
      isRenderButton,
      onSubmit,
      bossSubmit,
      confirmSubmit
    }
  }
})
</script>

<style lang="scss" scoped>
.plan-container {
  .plan-title {
    margin-top: 12px;
    text-align: left;
  }
  .team-group {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 20px repeat(auto-fit, minmax(200px, 1fr));
    width: 100%;
    overflow: auto;

    .order {
      font-size: 12px;
      font-weight: bold;

      .title {
        display: flex;
        align-items: center;
        height: 60px;
        border: 1px solid #bababa;
      }

      .order-item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 64px;
        border-right: 1px solid #bababa;
        border-left: 1px solid #bababa;
        border-bottom: 1px solid #bababa;
      }
      .order-item-op {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        border-right: 1px solid #bababa;
        border-left: 1px solid #bababa;
        border-bottom: 1px solid #bababa;
      }
    }

    .team-item {
      min-width: 200px;
      &:nth-child(even) {
        .team-title {
          background: #f4f4f4;
        }
      }
      &:nth-child(odd) {
        .team-title {
          background: #e6e6e6;
        }
      }
      .submit-btn {
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #bababa;
        border-bottom: 1px solid #bababa;
      }
    }

    .team-title {
      height: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #bababa;
      border-top: 1px solid #bababa;
      border-bottom: 1px solid #bababa;

      .name {
        height: 24px;
        line-height: 24px;
        font-weight: bold;
      }

      .date {
        font-size: 12px;
        font-weight: normal;
        height: 16px;
        line-height: 16px;
      }
    }

    .team-content {
      .goal {
        height: 64px;
        border-right: 1px solid #bababa;
        border-bottom: 1px solid #bababa;
      }
    }
  }
}
</style>
