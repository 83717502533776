<template>
  <div class="home-container" v-loading.fullscreen.lock="loading">
    <headerLayout :username="userInfo.name" />
    <div class="navbar">
      <div class="date">
        <el-date-picker
          v-model="date"
          size="small"
          type="month"
          value-format="YYYY-MM"
          :disabled-date="disabledDate"
          :clearable="false"
        >
        </el-date-picker>
      </div>
      <bulletinTitle
        :titles="bulletinBoard"
        :date="date + '-01'"
        :role="userInfo.role"
        @updateTeam="fetchTeamData"
      />
    </div>
    <div class="team-goals-bar">
      <h2 class="title" @click="switch_team_goals_bar_visible">
        チーム目標（半期、月間）
        <i v-show="!is_team_goals_bar_visible" class="el-icon-arrow-right el-icon-arrow"></i>
        <i v-show="is_team_goals_bar_visible" class="el-icon-arrow-down el-icon-arrow"></i>
      </h2>
      <el-collapse-transition>
        <div v-show="is_team_goals_bar_visible" class="container">
          <h2 class="month-range">{{ dataTitle }}目標（{{ rangeTitle }}）</h2>
          <TeamTitle
            :titles="teamGoalTitle"
            :date="date + '-01'"
            :role="userInfo.role"
            @updateTeam="fetchTeamData"
          />
          <h4 class="plan-title" style="padding: 12px 2px 0; text-align: left">
            半期目標（目標は最大３つまで設定）
          </h4>
          <YearPlan
            :dataTitle="dataTitle"
            :list="teamHalfYearGoal"
            :bossGoals="teamHalfYearGoalForBoss"
            :role="userInfo.role"
            :teamId="userInfo.teamId"
            :date="date"
            @updateTeam="fetchTeamData"
          />
          <div class="reset-plan" style="text-align: left; margin-top: 8px">
            <el-button
              v-if="userInfo.role === 'leader'"
              size="large"
              style="margin-top: 4px"
              type="primary"
              @click="newPlan"
              >目標の見直し・再設定
            </el-button>
            <el-button
              v-if="
                (userInfo.role === 'root' || userInfo.role === 'subRoot') && maxBossGoalsRows < 3
              "
              size="large"
              style="margin-top: 4px"
              type="primary"
              @click="newGoal"
              >今期目標の見直し
            </el-button>
          </div>

          <h4
            v-if="changeCount1TeamHalfYearGoal"
            class="plan-title"
            style="padding: 12px 2px 0; text-align: left"
          >
            半期目標の見直し_1
          </h4>
          <ResetYearPlan
            v-if="changeCount1TeamHalfYearGoal"
            :dataTitle="dataTitle"
            :list="changeCount1TeamHalfYearGoal"
            :role="userInfo.role"
            :teamId="userInfo.teamId"
            :date="date"
            @updateTeam="fetchTeamData"
          />
          <h4
            v-if="changeCount2TeamHalfYearGoal"
            class="plan-title"
            style="padding: 12px 2px 0; text-align: left"
          >
            半期目標の見直し_2
          </h4>
          <ResetYearPlan
            v-if="changeCount2TeamHalfYearGoal"
            :date="date"
            :dataTitle="dataTitle"
            :list="changeCount2TeamHalfYearGoal"
            :role="userInfo.role"
            :teamId="userInfo.teamId"
            @updateTeam="fetchTeamData"
          />
          <h4
            v-if="changeCount3TeamHalfYearGoal"
            class="plan-title"
            style="padding: 12px 2px 0; text-align: left"
          >
            半期目標の見直し_3
          </h4>
          <ResetYearPlan
            v-if="changeCount3TeamHalfYearGoal"
            :date="date"
            :dataTitle="dataTitle"
            :list="changeCount3TeamHalfYearGoal"
            :role="userInfo.role"
            :teamId="userInfo.teamId"
            @updateTeam="fetchTeamData"
          />
          <h2 class="month-range" style="padding-top: 20px">月間の目標</h2>
          <MonthPlan
            v-for="(item, index) in teamMonthlyGoal"
            :title="item.title"
            :list="item.data"
            :date="item.date"
            :role="userInfo.role"
            :teamId="userInfo.teamId"
            @updateTeam="fetchTeamData"
            :key="index"
          />
        </div>
      </el-collapse-transition>
    </div>
    <div class="private-goals-bar">
      <h2 class="title">個人目標（月間、週間、日次）</h2>
      <h3 class="month-range" style="text-align: left; font-size: 20px; padding-left: 6px">
        {{ currentMonth }}目標を達成するための行動計画と振り返り
      </h3>
      <MonthTitle
        :titles="userMonthTitle"
        :date="date + '-01'"
        :role="userInfo.role"
        @updateTeam="fetchTeamData"
      />
      <el-affix :offset="0">
        <el-tabs v-model="user" type="card" class="sp">
          <el-tab-pane
            v-for="item in userList"
            :key="item.userId"
            :label="item.name"
            :name="item.userId"
          ></el-tab-pane>
        </el-tabs>
        <ReportTitle
          v-if="user === 'root' || user === 'subRoot'"
          :titles="reportContentData"
          :date="date + '-01'"
          :role="userInfo.role"
          @updateTeam="fetchReport"
        />
        <div class="userMemoUrlDiv" v-if="userInfo.role === 'root' && user !== 'root'">
          <div class="userMemoUrlTitle">外部メモUrl:</div>
          <div v-if="isModifyUserMemoUrl" class="userMemoUrl">
            <el-input
              class="urlStr"
              v-model="userMemoUrl"
              placeholder="外部メモ用のUrlを設定してください"
            ></el-input>
            <el-button
              class="btn"
              type="primary"
              icon="el-icon-check"
              size="mini"
              plain
              @click="submitUserMemoUrl"
              >確定</el-button
            >
          </div>
          <div v-else class="userMemoUrl">
            <el-link class="urlStr" :href="userMemoUrl" target="_blank">{{
              userMemoUrlStr
            }}</el-link>
            <el-button
              class="btn"
              type="primary"
              icon="el-icon-edit"
              size="mini"
              plain
              @click="isModifyUserMemoUrl = true"
              >修正</el-button
            >
          </div>
        </div>
      </el-affix>

      <div class="container" v-if="user !== 'root' && user !== 'subRoot'">
        <div class="monthly-plan">
          <Expect
            :date="date"
            :plans="userData.userMonthlyPlan"
            :role="userInfo.role"
            :userId="user * 1"
            @updateUser="fetchPlanAndReview"
          />
          <Review
            :date="date"
            :plans="userData.userMonthlyPlanLookBack"
            :role="userInfo.role"
            :userId="user * 1"
            @updateUser="fetchPlanAndReview"
          />
        </div>
        <WeekPlan
          :date="date"
          :plans="userData.userWeeklyPlan"
          :reviews="userData.userWeeklyPlanLookBack"
          :role="userInfo.role"
          :userId="user * 1"
          @updateUser="fetchPlanAndReview"
        />
        <memo
          :date="date"
          :plans="userData.memo"
          :role="userInfo.role"
          :userId="user * 1"
          @updateUser="fetchPlanAndReview"
        />
        <DailyPlan
          :date="date"
          :plans="userData.userDailyPlan"
          :reviews="userData.userDailyPlanLookBack"
          :role="userInfo.role"
          :userId="user * 1"
          @updateUser="fetchPlanAndReview"
        />
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'

import {
  fetchResetTeamGoal,
  fetchTeamGoal,
  fetchTeamGoalTitle,
  fetchTeamMonthTitle
} from '@/api/team'
import { fetchPlanAndReview, fetchReport, fetchUserList, postUserMemoUrl } from '@/api/user'
import headerLayout from '@/components/common/header-layout'
import bulletinTitle from '@/components/business/bulletinPlan'
import TeamTitle from '@/components/business/TeamTitle'

import YearPlan from '@/components/business/YearPlan'
import ResetYearPlan from '@/components/business/ResetYearPlan'
import MonthPlan from '@/components/business/MonthPlan'

import MonthTitle from '@/components/business/MonthTitle.vue'
import ReportTitle from '@/components/business/ReportTitle.vue'
import Expect from '@/components/Expect/index.vue'
import Review from '@/components/Review/index.vue'
import memo from '@/components/memo/index.vue'
import DailyPlan from '@/components/DailyPlan/index.vue'
import WeekPlan from '@/components/WeekPlan/index.vue'

import { computed, defineComponent, ref, watch } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'Test',
  components: {
    headerLayout,
    bulletinTitle,
    TeamTitle,
    YearPlan,
    ResetYearPlan,
    MonthPlan,

    MonthTitle,
    ReportTitle,
    Expect,
    Review,
    memo,
    DailyPlan,
    WeekPlan
  },
  data() {
    return {
      isSeted: false,
      isModifyUserMemoUrl: false,
      changeCount1TeamHalfYearGoal: null,
      changeCount2TeamHalfYearGoal: null,
      changeCount3TeamHalfYearGoal: null,
      bulletinBoard: '',
      userMemoUrl: '',
      pickerOptions: {
        disabledDate(time) {
          return true
        }
      }
    }
  },
  setup: () => {
    const store = useStore()
    const loading = ref(false)
    const userInfo = computed(() => store.state.user.userInfo)
    const date = ref(dayjs().format('YYYY-MM'))
    const currentMonth = computed(() => {
      if (date.value) {
        return dayjs(date.value).year() + '年' + (Number(dayjs(date.value).month()) + 1) + '月'
      } else {
        return ''
      }
    })

    const teamGoalTitle = ref('')
    const userMonthTitle = ref('')

    const teamHalfYearGoal = ref([])
    const teamHalfYearGoalForBoss = ref([])
    const teamMonthlyGoal = ref([])
    const dataTitle = ref('')
    const reportContentData = ref('')

    const user = ref('4')
    const userList = ref([])
    const userData = ref({
      memo: [],
      userDailyPlan: [],
      userDailyPlanLookBack: [],
      userMonthlyPlan: [],
      userMonthlyPlanLookBack: [],
      userWeeklyPlan: [],
      userWeeklyPlanLookBack: []
    })

    const getUserList = async () => {
      await fetchUserList(date.value + '-01').then((res) => {
        userList.value = res.data.map((item) => {
          return {
            name: item.name,
            userId: item.userId + '',
            userMemoUrl: item.userMemoUrl
          }
        })
      })
      userList.value = [
        {
          name: '注意事项',
          userId: 'root',
          userMemoUrl: ''
        },
        ...userList.value
      ]
    }
    return {
      loading,
      is_team_goals_bar_visible: ref(true),
      date,
      currentMonth,
      userInfo,
      teamGoalTitle,
      userMonthTitle,
      dataTitle,
      rangeTitle: '',
      teamHalfYearGoal,
      teamHalfYearGoalForBoss,
      teamMonthlyGoal,
      user,
      userList,
      getUserList,
      userData,
      reportContentData
    }
  },
  computed: {
    renderReset() {},
    userMemoUrlStr() {
      if (this.userMemoUrl.length >= 103) {
        return this.userMemoUrl.substr(0, 100) + '...'
      }
      return this.userMemoUrl
    },
    maxBossGoalsRows() {
      const changeCount3 = this.teamHalfYearGoalForBoss.find((item) => item.changeCount === 2)
      const changeCount2 = this.teamHalfYearGoalForBoss.find((item) => item.changeCount === 1)
      return changeCount3 ? 3 : changeCount2 ? 2 : 1
    }
  },
  watch: {
    async date() {
      this.loading = true
      await this.fetchTeamData()
      this.loading = false
      await this.getUserList()
      this.user = this.userInfo.userId + ''
      await this.fetchReport()
      await this.fetchPlanAndReview()
    },
    user(id) {
      id === 'root' ? this.fetchReport() : this.fetchPlanAndReview()
    }
  },
  methods: {
    disabledDate(time) {
      const date = new Date()
      const disableDateLine = date.setTime(date.getTime() + 3600 * 1000 * 24 * 14)
      return time.getTime() > disableDateLine
    },
    switch_team_goals_bar_visible() {
      this.is_team_goals_bar_visible = !this.is_team_goals_bar_visible
    },
    async fetchTeamData() {
      try {
        await fetchTeamGoal(this.date + '-01').then((res) => {
          this.dataTitle = res.data.dateTitle
          this.rangeTitle = res.data.title
          this.bulletinBoard = res.data.bulletinBoard
          this.teamMonthlyGoal = res.data.teamMonthlyGoal
          this.teamHalfYearGoal = res.data.teamHalfYearGoal
          this.teamHalfYearGoalForBoss = res.data.teamHalfYearGoalForBoss
        })
        await fetchResetTeamGoal(this.date + '-01').then((res) => {
          const changeCount1TeamHalfYearGoal = res.data.changeCount1TeamHalfYearGoal
          const changeCount2TeamHalfYearGoal = res.data.changeCount2TeamHalfYearGoal
          const changeCount3TeamHalfYearGoal = res.data.changeCount3TeamHalfYearGoal

          if (changeCount1TeamHalfYearGoal !== null) {
            this.changeCount1TeamHalfYearGoal = changeCount1TeamHalfYearGoal.map((team) => {
              return {
                ...team
              }
            })
          } else {
            this.changeCount1TeamHalfYearGoal = null
          }
          if (changeCount2TeamHalfYearGoal !== null) {
            this.changeCount2TeamHalfYearGoal = changeCount2TeamHalfYearGoal.map((team) => {
              return {
                ...team
              }
            })
          } else {
            this.changeCount2TeamHalfYearGoal = null
          }
          if (changeCount3TeamHalfYearGoal !== null) {
            this.changeCount3TeamHalfYearGoal = changeCount3TeamHalfYearGoal.map((team) => {
              return {
                goals
              }
            })
          } else {
            this.changeCount3TeamHalfYearGoal = null
          }
          this.isSeted = false
        })
        await fetchTeamGoalTitle(this.date + '-01').then((res) => {
          this.teamGoalTitle =
            res.data.titles ||
            '管理者の方はここをクリックして、半期目標設定説明をご入力してください。'
        })
        await fetchTeamMonthTitle(this.date + '-01').then((res) => {
          this.userMonthTitle =
            res.data.titles ||
            '管理者の方はここをクリックして、月間個人目標設定説明をご入力してください。'
        })
        // fetchResetTeamGoal,
      } catch (e) {}
    },

    async fetchPlanAndReview() {
      try {
        await fetchPlanAndReview(this.user * 1, this.date + '-01').then((res) => {
          this.userData = res.data
          this.getUserMemoUrl()
        })
      } catch (e) {}
    },
    async fetchReport() {
      try {
        await fetchReport(this.date + '-01').then((res) => {
          this.reportContentData = res.data.content
        })
      } catch (e) {}
    },
    getUserMemoUrl() {
      this.userList.forEach((item) => {
        if (this.user === item.userId) {
          this.userMemoUrl = item.userMemoUrl
        }
      })
    },
    submitUserMemoUrl() {
      postUserMemoUrl({
        userId: this.user * 1,
        userMemoUrl: this.userMemoUrl
      }).then((res) => {
        this.isModifyUserMemoUrl = false
      })
    },
    newGoal() {
      if (this.maxBossGoalsRows > 2) return
      const preGoalForBoss = JSON.parse(JSON.stringify(this.teamHalfYearGoalForBoss))
      this.teamHalfYearGoal.forEach((team) => {
        preGoalForBoss.push({
          changeCount: this.maxBossGoalsRows,
          date: this.date + '-01',
          goal: '',
          teamId: team.teamId,
          teamName: team.teamName
        })
      })
      this.teamHalfYearGoalForBoss = preGoalForBoss
    },
    newPlan() {
      if (
        this.changeCount1TeamHalfYearGoal &&
        this.changeCount2TeamHalfYearGoal &&
        this.changeCount3TeamHalfYearGoal
      ) {
        return false
      }
      if (this.isSeted) {
        return false
      }
      if (!this.changeCount1TeamHalfYearGoal) {
        const data = JSON.parse(JSON.stringify(this.teamHalfYearGoal))
        this.changeCount1TeamHalfYearGoal = data.map((team) => {
          const goals = team.goals.map((goal) => '')

          return {
            ...team,
            goals,
            changeCount: 1,
            isChanged: false,
            updatedAt: ''
          }
        })
        this.isSeted = true
      } else if (!this.changeCount2TeamHalfYearGoal) {
        const data = JSON.parse(JSON.stringify(this.teamHalfYearGoal))
        this.changeCount2TeamHalfYearGoal = data.map((team) => {
          const goals = team.goals.map((goal) => '')
          return {
            ...team,
            goals,
            changeCount: 2,
            isChanged: false,
            updatedAt: ''
          }
        })
        this.isSeted = true
      } else {
        const data = JSON.parse(JSON.stringify(this.team_goals))
        this.changeCount3TeamHalfYearGoal = data.map((team) => {
          const goals = team.goals.map((goal) => '')
          return {
            ...team,
            goals,
            changeCount: 3,
            isChanged: false,
            updatedAt: ''
          }
        })
        this.isSeted = true
      }
    }
  },
  async mounted() {
    this.loading = true
    await this.fetchTeamData()
    await this.getUserList()

    this.user = this.userInfo.userId + ''
    await this.fetchPlanAndReview()
    this.loading = false
  }
})
</script>

<style lang="scss" scoped>
.home-container {
  padding-bottom: 30px;
}
.el-icon-arrow {
  font-size: 12px;
  margin-top: 18px;
}
.month-range {
  margin-top: 12px;
  text-align: left;
}
.title {
  height: 50px;
  line-height: 50px;
  text-align: left;
  text-indent: 20px;
  background: #565656;
  color: #fff;
  cursor: pointer;
}
.el-icon-arrow {
  float: right;
  margin: 13px;
  font-size: 24px;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 20px;

  .user-name {
    font-size: 16px;
    font-weight: bold;
    color: #1f2a3e;
  }
}
.userMemoUrlDiv {
  width: calc(100% - 4px);
  background-color: #c6fffc;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 0;
  border-top: 2px solid #565656;
  border-left: 2px solid #565656;
  border-right: 2px solid #565656;
  .userMemoUrlTitle {
    width: 80px;
    font-size: 12px;
    margin-left: 12px;
    text-align: left;
  }
  .userMemoUrl {
    width: calc(100% - 80px);
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    .urlStr {
      padding-right: 12px;
      max-width: calc(100% - 165px);
      overflow: hidden;
    }
    .btn {
      min-width: 73px;
      margin-right: 12px;
    }
  }
}

.team-goals-bar {
  padding: 0 20px;
  .container {
  }
}
.private-goals-bar {
  box-sizing: border-box;
  padding: 20px;
  .title {
    height: 50px;
    line-height: 50px;
    text-align: left;
    text-indent: 20px;
    background: #565656;
    color: #fff;
    cursor: pointer;
  }

  .plan-text {
    margin-top: 8px;
    text-align: left;
    font-size: 12px;
    text-indent: 20px;
  }

  .container {
    border: 2px solid #000;

    .monthly-plan {
      display: flex;
    }
  }
}
</style>
