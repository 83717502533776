<template>
  <div class="login-container">
    <div class="form-container">
      <div class="title">
        <el-image style="height: 100px; padding-top: 10px" src="/narrative_logo.png" fit="fill" />
        <!-- <h2 class="sub-title">One To One Marketing部　ナラティブ</h2> -->
      </div>
      <el-form
        ref="formRef"
        :model="formState"
        :rules="formRules"
        label-width="0px"
        style="margin-top: 60px"
      >
        <el-form-item prop="username">
          <el-input
            v-model="formState.username"
            prefix-icon="el-icon-user"
            placeholder="ユーザーID"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="formState.password"
            prefix-icon="el-icon-key"
            show-password
            placeholder="パスワード"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            :loading="loading"
            style="width: 120px; background: #000; color: #fff; outline: none; border: none"
            type="primary"
            @click="onSubmit"
            >ログイン</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  setup: () => {
    const router = useRouter()
    const store = useStore()
    const formRef = ref()
    const loading = ref(false)
    const formState = reactive({
      name: '',
      password: ''
    })
    const formRules = reactive({
      username: [{ required: true, message: 'ユーザーIDが入力されていません', trigger: 'blur' }],
      password: [{ required: true, message: 'パスワードを入力してください', trigger: 'blur' }]
    })
    const onSubmit = () => {
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true
          store
            .dispatch('user/login', formState)
            .then(() => {
              router.push({ path: '/' })
              loading.value = false
            })
            .catch((e) => {
              loading.value = false
            })
        } else {
          return false
        }
      })
    }
    return {
      loading,
      formRef,
      formState,
      formRules,
      onSubmit
    }
  }
})
</script>

<style lang="scss" scoped>
.login-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f7fa;

  .form-container {
    .title {
      color: #000;
    }
    width: 360px;
  }
}
</style>
