import Axios from 'axios'
import { ElMessage } from 'element-plus'
// @ts-ignore
import { getToken, removeToken } from '@/utils/auth'

const baseURL = process.env.VUE_APP_BASE_URL
// const baseURL = 'https://narrative-api.o2o-marketing.com'

const axios = Axios.create({
  baseURL,
  timeout: 20000
})

axios.interceptors.request.use(
  (request) => {
    if (request.url !== '/login') {
      request.headers['Authorization'] = 'Bearer ' + getToken()
    }
    return request
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      ElMessage({
        message: '認証失敗しました。再ログインしてください',
        type: 'error',
        duration: 3000,
        showClose: true
      })
      removeToken()
      setTimeout(() => {
        location.reload() // 修复401后，前进后退失效问题
      }, 3000)
    } else {
      ElMessage.error(`${error}`)
    }
    return Promise.reject(error)
  }
)

export default axios
