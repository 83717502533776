<template>
  <el-table :data="meetingData" stripe border height='70vh' header-cell-style='background: #565656; color: white'>
    <el-table-column label="No." width="70" sortable sort-by='meetingId' align='center'>
      <template #default="scope">
        <span>
          <el-link :href="setMtgUrl(scope.row.meetingId)" type='primary' target="_blank">{{ scope.row.meetingId }}</el-link>
        </span>
      </template>
    </el-table-column>
    <el-table-column label="開催日" width="160" sortable sort-by='before.date' header-align='center' align='center'>
      <template #default="scope">
        <span> {{ setDateStr(scope.row.before.date) }}</span>
      </template>
    </el-table-column>
    <el-table-column label="主催者" width="120" header-align='center' align='center' :filters='filterList' :filter-method="filterHandler">
      <template #default="scope">
        <span v-html='scope.row.before.organizer'></span>
      </template>
    </el-table-column>
    <el-table-column label="会議名" width="200" header-align='center'>
      <template #default="scope">
        <span v-html='scope.row.before.meetingPurpose'></span>
      </template>
    </el-table-column>
    <el-table-column label="議題" header-align='center'>
      <template #default="scope">
        <span v-html='scope.row.before.theme'></span>
      </template>
    </el-table-column>
    <!-- <el-table-column label="目　的" header-align='center'>
      <template #default="scope">
        <span v-html='scope.row.before.meetingReason'></span>
      </template>
    </el-table-column> -->
  </el-table>
  <el-pagination
    style='padding-top:16px'
    @current-change="onChangePage"
    :current-page="pageIndex"
    layout="prev, pager, next"
    :total="meetingCount"
    :page-size="20"
    background
    hide-on-single-page
    prev-text='前へ'
    next-text='次へ'
  >
  </el-pagination>
</template>

<script>
import dayjs from 'dayjs'

import { fetchMeetingMinute } from '@/api/meeting'

import { computed, defineComponent, ref, watch } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'Test',
  components: {},
  data() {
    return {
      page: 1,
      pageIndex: 1,
      meetingCount: 0,
      dialogMtgTableVisible: false,
    }
  },
  setup: () => {
    const store = useStore()
    const loading = ref(false)
    const userInfo = computed(() => store.state.user.userInfo)
    const date = ref(dayjs().format('YYYY-MM'))
    const meetingData = ref([])

    return {
      loading,
      date,
      userInfo,
      meetingData
    }
  },
  computed: {
    filterList() {
      let list = []
      if (this.meetingCount === 0) return list
      this.meetingData.forEach((item) => {
        const nameHtml = item.before.organizer
        const nameText = nameHtml.replace(/<[^>]+>/g, '')
        if (!list.find(e => e.text === nameText)) {
          list.push({text: nameText, value: nameHtml})
        }
      })
      return list
    }
  },
  methods: {
    async fetchMeetingMinute(id) {
      try {
        await fetchMeetingMinute(this.date + '-01', this.page, id).then((res) => {
          this.meetingTitle =
            res.data.titles || '管理者の方はここをクリックして、会議設定説明をご入力してください。'
          this.meetingData = res.data.meetingData
          this.meetingCount = res.data.meetingCount
          this.pageIndex = res.data.meetingIndex
        })
      } catch (e) {}
    },
    async onChangePage(page) {
      this.page = page
      this.loading = true
      await this.fetchMeetingMinute()
      this.loading = false
    },
    setDateStr(date) {
      let weekJa = '日'
      switch (dayjs(date).day()) {
        case 0:
          weekJa = '日'
          break
        case 1:
          weekJa = '月'
          break
        case 2:
          weekJa = '火'
          break
        case 3:
          weekJa = '水'
          break
        case 4:
          weekJa = '木'
          break
        case 5:
          weekJa = '金'
          break
        case 6:
          weekJa = '土'
          break
        default:
          ''
          break
      }
      return `${dayjs(date).format('YYYY-MM-DD')} (${weekJa})`
    },
    setMtgUrl(mtgId) {
      return `${process.env.VUE_APP_ROUTE_URL}#/meeting?id=${mtgId}`
    },
    filterHandler(value, row) {
      return row.before.organizer === value
    },
  },
  async mounted() {
    const { id } = this.$route.query
    this.loading = true
    await this.fetchMeetingMinute(id)
    this.loading = false
  },
})
</script>

<style lang="scss" scoped>
.meeting-container {
  padding-bottom: 30px;
}
.title {
  height: 50px;
  line-height: 50px;
  text-align: left;
  text-indent: 20px;
  background: #565656;
  color: #fff;
  cursor: pointer;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 20px;
}

.team-goals-bar {
  padding: 0 20px;
  text-align: left;
}
</style>
