export function getToken() {
  return localStorage.getItem('ACCESS_TOKEN_KEY')
}
export function getUserInfo() {
  return localStorage.getItem('userInfo')
}

export function setToken(token) {
  localStorage.setItem('ACCESS_TOKEN_KEY', token)
}
export function setUserInfo(payload) {
  localStorage.setItem('userInfo', payload)
}

export function removeToken() {
  localStorage.removeItem('ACCESS_TOKEN_KEY')
}
export function removeUserInfo() {
  localStorage.removeItem('userInfo')
}
