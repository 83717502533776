<template>
  <div>
    <el-config-provider :locale="locale">
      <router-view></router-view>
    </el-config-provider>
  </div>
</template>
<script>
import { ElConfigProvider } from 'element-plus'
import ja from 'element-plus/lib/locale/lang/ja'
export default {
  name: 'App',
  components: {
    [ElConfigProvider.name]: ElConfigProvider
  },
  data() {
    return {
      locale: ja
    }
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
html,
body {
  width: 100%;
  height: 100%;
}
#app {
  height: 100%;
  width: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.ck-editor__editable_inline {
  min-height: 230px;
  max-height: 500px;
}
.ck-editor__main {
  overflow-y: scroll;
}
</style>
