import { getToken, setToken, getUserInfo, setUserInfo } from '@/utils/auth'
import { loginByUsername } from '@/api/user'

const state = {
  token: getToken(),
  userInfo: JSON.parse(getUserInfo())
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USER_INFO: (state, payload) => {
    state.userInfo = payload
  },
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const username = userInfo.username.trim()
    const data = {
      username: username,
      password: userInfo.password,
    }
    return new Promise((resolve, reject) => {
      loginByUsername(data).then(response => {
        const data = response.data
        commit('SET_TOKEN', data.token)
        setToken(data.token)
        commit('SET_USER_INFO', data)
        setUserInfo(JSON.stringify(data))
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
