<template>
  <div class="meeting-container" v-loading="loading" id="meeting-top">
    <headerLayout :username="userInfo.name" />
    <div class="navbar">
      <div class="date">
        <el-date-picker
          v-model="date"
          size="small"
          type="month"
          value-format="YYYY-MM"
          :disabled-date="disabledDate"
          :clearable="false"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="team-goals-bar" id="aaa">
      <h2 class="title">MTG（ミーティング）・会議、来社・外交の準備記録と事後記録</h2>
      <MeetingTitle
        :titles="meetingTitle"
        :date="date + '-01'"
        :role="userInfo.role"
        @updateMeeting="fetchMeetingMinute"
      />
      <el-button
        v-if="isCurrentMonth"
        size="large"
        type="primary"
        style="font-size: 16px; width: 180px; margin-bottom: 20px"
        @click="newMeet"
        >新規会議追加
      </el-button>
      <el-button
        size="large"
        style="font-size: 16px; width: 180px; margin-bottom: 20px"
        @click="showMtgList"
        >MTG・会議の一覧
      </el-button>
      <Meeting
        v-for="item in meetingData"
        :key="item.meetingId"
        :meetingData="item"
        :role="userInfo.role"
        :date="date"
        @updateMeeting="fetchMeetingMinute"
      />
    </div>
    <el-pagination
      @current-change="onChangePage"
      :current-page="pageIndex"
      layout="prev, pager, next"
      :total="meetingCount"
      :page-size="20"
      background
      hide-on-single-page
      prev-text="前へ"
      next-text="次へ"
    >
    </el-pagination>
    <el-dialog
      v-model="dialogMtgTableVisible"
      title="MTG・会議の一覧"
      width="90%"
      top="5vh"
      :destroy-on-close="true"
      :lock-scroll="true"
    >
      <MeetingList />
    </el-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { fetchMeetingMinute, postBeforeMeetingMinute } from '@/api/meeting'
import headerLayout from '@/components/common/header-layout'
import MeetingTitle from '@/components/business/MeetingTitle'
import Meeting from '@/components/meeting'
import MeetingList from '@/components/meeting/list'
import { computed, defineComponent, ref, watch } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'Test',
  components: {
    headerLayout,
    MeetingTitle,
    Meeting,
    MeetingList
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return true
        }
      },
      page: 1,
      pageIndex: 1,
      meetingCount: 0,
      dialogMtgTableVisible: false
    }
  },
  setup: () => {
    const store = useStore()
    const loading = ref(false)
    const userInfo = computed(() => store.state.user.userInfo)
    const date = ref(dayjs().format('YYYY-MM'))

    const meetingTitle = ref('')
    const meetingData = ref([])

    return {
      loading,
      date,
      userInfo,
      meetingTitle,
      meetingData
    }
  },
  computed: {
    isCurrentMonth() {
      return (
        dayjs().format('MM') === dayjs(this.date).format('MM') &&
        dayjs().format('YYYY') === dayjs(this.date).format('YYYY')
      )
    }
  },
  watch: {
    async date() {
      this.loading = true

      await this.fetchMeetingMinute()

      this.loading = false
    }
  },
  methods: {
    disabledDate(time) {
      const date = new Date()
      const disableDateLine = date.setTime(date.getTime() + 3600 * 1000 * 24 * 14)
      return time.getTime() > disableDateLine
    },
    showMtgList() {
      this.dialogMtgTableVisible = true
    },
    newMeet() {
      const data = {
        date: dayjs().format('YYYY-MM-DD'),
        startTime: '',
        endTime: '',
        location: '',
        organizer: '',
        writer: '',
        member: '',
        theme: '',
        meetingPurposeType: [],
        meetingType: '0',
        meetingPurpose: '',
        meetingReason: '',
        meetingGoal: '',
        advanceToDo: '',
        additionalInformation: '',
        howToProceed: '',
        startTimeAndEndTime: [
          dayjs().format('YYYY-MM-DD HH:mm'),
          dayjs().set('hours', 19).set('minute', 0).format('YYYY-MM-DD HH:mm')
        ],
        isAutoLock: false
      }

      postBeforeMeetingMinute(data).then(() => {
        this.onChangePage(1)
      })
    },
    async fetchMeetingMinute(id) {
      try {
        await fetchMeetingMinute(this.date + '-01', this.page, id).then((res) => {
          this.meetingTitle =
            res.data.titles || '管理者の方はここをクリックして、会議設定説明をご入力してください。'
          this.meetingData = res.data.meetingData
          this.meetingCount = res.data.meetingCount
          this.pageIndex = res.data.meetingIndex
        })
      } catch (e) {}
    },
    onScrollMeeting(id) {
      setTimeout(() => {
        document
          .querySelector('#meeting' + id)
          .scrollIntoView({ block: 'center', behavior: 'smooth' })
      }, 300)
    },
    async onChangePage(page) {
      this.page = page
      this.loading = true
      await this.fetchMeetingMinute()
      this.loading = false
      setTimeout(() => {
        document.querySelector('#meeting-top').scrollIntoView()
      }, 300)
    }
  },
  async mounted() {
    const { id } = this.$route.query
    this.loading = true
    await this.fetchMeetingMinute(id)
    this.loading = false
    if (this.meetingData.find((item) => item.meetingId == id)) {
      this.onScrollMeeting(id)
    }
  }
})
</script>

<style lang="scss" scoped>
.meeting-container {
  padding-bottom: 30px;
}
.title {
  height: 50px;
  line-height: 50px;
  text-align: left;
  text-indent: 20px;
  background: #565656;
  color: #fff;
  cursor: pointer;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 20px;
}

.team-goals-bar {
  padding: 0 20px;
  text-align: left;
}
</style>
