<template>
  <div class="header-bar">
    <el-image style="height: 80px; padding-top: 10px" src="/narrative_logo.png" fit="fill" />
    <!-- <h2 class="main-title" style="text-align: left">
      One To One Marketing部　ナラティブ
    </h2> -->
    <div class="user-setting">
      <el-button-group :class="[meetingButtonGroup]">
        <el-button
          style="border: none"
          :type="$route.name === 'Home' ? 'primary' : ''"
          @click="toPage('/')"
          >計画と結果の日報</el-button
        >
        <el-button
          style="border: none"
          :type="$route.name === 'Marketing' ? 'primary' : ''"
          @click="toPage('/marketing')"
          >マーケティングの施策</el-button
        >
        <el-button
          style="border: none"
          :type="$route.name === 'Meeting' ? 'primary' : ''"
          @click="toPage('/meeting')"
          >MTG・会議の設計/準備/記録</el-button
        >
      </el-button-group>
      <el-dropdown>
        <span class="el-dropdown-link">
          <el-avatar @mouseover="showMenu"> {{ username[0] }} </el-avatar>
        </span>
        <el-dropdown-menu slot="dropdown" v-show="menuShow" @mouseleave="hidenMenu">
          <el-dropdown-item @click.native="logout">ログアウト</el-dropdown-item>
          <el-dropdown-item @click.native="changePassword">パスワード変更</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { removeToken, removeUserInfo } from '@/utils/auth'
import user from '@/store/modules/user'

export default {
  name: 'header-layout',
  props: {
    username: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      menuShow: false,
      meetingButtonGroup: 'meeting-button-group'
    }
  },
  methods: {
    logout() {
      removeToken()
      removeUserInfo()
      window.location.reload()
    },
    changePassword() {
      console.log(this.$route.name)
      this.$router.push('/changePassword')
    },
    toPage(path) {
      this.$router.push(path)
    },
    showMenu() {
      this.menuShow = true
    },
    hidenMenu() {
      this.menuShow = false
    }
  }
}
</script>
<style>
.user-setting .el-dropdown-menu {
  width: 165px !important;
  left: -118px !important;
  z-index: 10000 !important;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
<style lang="scss" scoped>
.header-bar {
  height: 80px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .user-setting {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .user-name {
      font-weight: bold;
      margin-right: 12px;
    }
  }
}
</style>
