<template>
  <div class="home-container" v-loading="loading">
    <headerLayout :username="userInfo.name" />
    <div class="navbar">
      <div class="date">
        <el-date-picker
          v-model="date"
          size="small"
          type="month"
          value-format="YYYY-MM"
          :disabled-date="disabledDate"
          :clearable="false"
        >
        </el-date-picker>
      </div>
    </div>

    <div class="team-goals-bar">
      <el-affix :offset="0">
        <el-tabs v-model="currentTeam" style="width: calc(100vw - 40px)" type="card" class="normal">
          <el-tab-pane
            v-for="item in teamList"
            :key="item.id"
            :label="item.team_name"
            :name="item.id"
          ></el-tab-pane>
        </el-tabs>
      </el-affix>
      <div class="marketing-title-wrapper">
        <bulletinTitle
          title="【顧客に対する料金等のデフォルト設定】"
          :titles="firstBulletin"
          :date="date + '-01'"
          :role="userInfo.role"
          @updateTeam="(value) => onUpdateBulletin('firstBulletin', value)"
        />
        <bulletinTitle
          title="【記載上の注意】"
          :titles="secBulletin"
          :date="date + '-01'"
          :role="userInfo.role"
          @updateTeam="(value) => onUpdateBulletin('secBulletin', value)"
          :wrapperStyle="{
            borderLeft: 'none'
          }"
        />
      </div>
      <template v-if="currentTeam === 5 || currentTeam === 8">
        <ResultsSpecial
          :responseData="resultsData"
          :currentTeam="currentTeam"
          :date="date"
          @updateMaketing="fetchMaketingDefault"
        />
        <CampaignSpecial
          :responseData="campaignData"
          :currentTeam="currentTeam"
          :date="date"
          @updateMaketing="fetchMaketingCampaign"
        />
      </template>
      <template v-else>
        <Results
          :responseData="resultsData"
          :currentTeam="currentTeam"
          :date="date"
          @updateMaketing="fetchMaketingDefault"
        />
        <Advertising
          :responseData="advertisingData"
          :currentTeam="currentTeam"
          :date="date"
          @updateMaketing="fetchMaketingAdvertising"
        />
        <Campaign
          :responseData="campaignData"
          :currentTeam="currentTeam"
          :date="date"
          @updateMaketing="fetchMaketingCampaign"
        />
      </template>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'

import {
  fetchMaketing,
  fetchMaketingDefault,
  fetchTeam,
  fetchMaketingBulletin,
  postMarketingBulletin
} from '@/api/marketing'
import headerLayout from '@/components/common/header-layout'
import bulletinTitle from '@/components/marketing/bulletinPlan.vue'

import Results from '@/components/marketing/Results.vue'
import Advertising from '@/components/marketing/Advertising.vue'
import Campaign from '@/components/marketing/Campaign.vue'
import ResultsSpecial from '@/components/marketing/ResultsSpecial.vue'
import CampaignSpecial from '@/components/marketing/CampaignSpecial.vue'

import { computed, defineComponent, ref, watch } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'Test',
  components: {
    headerLayout,
    bulletinTitle,
    Results,
    Advertising,
    Campaign,
    ResultsSpecial,
    CampaignSpecial
  },
  data() {
    return {
      currentTeam: null,
      teamList: [],
      resultsData: [],
      advertisingData: [],
      campaignData: [],
      firstBulletin: '',
      secBulletin: ''
    }
  },
  setup: () => {
    const store = useStore()
    const loading = ref(false)
    const userInfo = computed(() => store.state.user.userInfo)
    const date = ref(dayjs().format('YYYY-MM'))

    return {
      loading,
      date,
      userInfo
    }
  },
  computed: {},
  watch: {
    async date() {
      this.fetchAll()
    },
    async currentTeam() {
      this.fetchAll()
    }
  },
  methods: {
    disabledDate(time) {
      const date = new Date()
      const disableDateLine = date.setTime(date.getTime() + 3600 * 1000 * 24 * 14)
      return time.getTime() > disableDateLine
    },
    async fetchTeam() {
      this.loading = true
      try {
        await fetchTeam().then((res) => {
          this.teamList = res.data
          this.currentTeam = this.teamList[0].id
        })

        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },

    async fetchAll() {
      this.loading = true
      try {
        await fetchMaketingBulletin(this.currentTeam).then((res) => {
          this.firstBulletin = res.data?.firstBulletin ?? ''
          this.secBulletin = res.data?.secBulletin ?? ''
        })
        await fetchMaketingDefault(this.date, this.currentTeam).then((res) => {
          this.resultsData = res.data
        })
        await fetchMaketing(this.date, this.currentTeam, 1).then((res) => {
          this.advertisingData = res.data
        })
        await fetchMaketing(this.date, this.currentTeam, 2).then((res) => {
          this.campaignData = res.data
        })

        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    onUpdateBulletin(key, value) {
      const parms = {
        teamId: this.currentTeam,
        [key]: value
      }
      postMarketingBulletin(parms).then(() => {
        this.fetchBulletin()
      })
    },

    async fetchBulletin() {
      try {
        await fetchMaketingBulletin(this.currentTeam).then((res) => {
          this.firstBulletin = res.data?.firstBulletin ?? ''
          this.secBulletin = res.data?.secBulletin ?? ''
        })
      } catch (e) {}
    },

    async fetchMaketingDefault() {
      try {
        await fetchMaketingDefault(this.date, this.currentTeam).then((res) => {
          this.resultsData = res.data
        })
      } catch (e) {}
    },
    async fetchMaketingAdvertising() {
      try {
        await fetchMaketing(this.date, this.currentTeam, 1).then((res) => {
          this.advertisingData = res.data
        })
      } catch (e) {}
    },
    async fetchMaketingCampaign() {
      try {
        await fetchMaketing(this.date, this.currentTeam, 2).then((res) => {
          this.campaignData = res.data
        })
      } catch (e) {}
    }
  },
  async mounted() {
    await this.fetchTeam()
  }
})
</script>

<style lang="scss" scoped>
.marketing-container {
  padding-bottom: 30px;
}
.title {
  height: 50px;
  line-height: 50px;
  text-align: left;
  text-indent: 20px;
  background: #565656;
  color: #fff;
  cursor: pointer;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 20px;
}

.team-goals-bar {
  padding: 0 20px;
  text-align: left;
}

.marketing-title-wrapper {
  width: calc(100vw - 40px);
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100px;
  margin-bottom: 20px;
}
</style>
